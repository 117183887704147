msgid ""
msgstr ""
"POT-Creation-Date: 2024-02-08 15:57-0500\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: pt\n"
"Project-Id-Version: 27b1a082298d0ee642e56f654bcd7273\n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: 2024-07-30 12:33\n"
"Last-Translator: \n"
"Language-Team: Portuguese, Brazilian\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: 27b1a082298d0ee642e56f654bcd7273\n"
"X-Crowdin-Project-ID: 10\n"
"X-Crowdin-Language: pt-BR\n"
"X-Crowdin-File: /master/src/locales/en.po\n"
"X-Crowdin-File-ID: 16450\n"

msgid "{adFormatName} are not available for self-managed campaigns. Please contact sales to inquire."
msgstr "{adFormatName} não estão disponíveis para campanhas autogerenciadas. Por favor, entre em contato com o time de vendas para obter mais informações."

msgid "{billingType} price must be {price} or higher"
msgstr "O preço de {billingType} deve ser {price} ou superior"

msgid "{campaignFormat} pricing configuration option(s):"
msgstr "Opção(ões) de configuração de preços para {campaignFormat}:"

msgid "{length}/{maxLength} characters"
msgstr "{length}/{maxLength} caracteres"

msgid "{type} status cannot be updated"
msgstr "Não é possível atualizar o status de {type}"

msgid "*Limited time only. Available to new advertisers, and those who ran campaigns prior to September 30, 2023. Eligible for Push Notification and Newsfeed ads."
msgstr "*Apenas por tempo limitado. Disponível para novos anunciantes e para aqueles que realizaram campanhas antes de 30 de setembro de 2023. Elegível para anúncios de notificação push e feed de notícias."

msgid "+{count} more"
msgstr "+{count} mais"

msgid "1 Day"
msgstr "1 Dia"

msgid "30 Days"
msgstr "30 Dias"

msgid "401 Forbidden"
msgstr "401 Proibido"

msgid "7 Days"
msgstr "7 Dias"

msgid "A forbidden error was returned. You may need to log out and back in again."
msgstr "Um erro de proibição foi retornado. Você pode precisar sair e entrar novamente."

msgid "A login email is on the way"
msgstr "Um e-mail de login está sendo enviado"

msgid "About Brave Ads"
msgstr "Sobre os Anúncios Brave"

msgid "Account"
msgstr "Conta"

msgid "Account Balance"
msgstr ""

msgid "Account Balance will be applied to this campaign"
msgstr ""

msgid "Account Settings"
msgstr "Configurações da Conta"

msgid "Activate"
msgstr "Ativar"

msgid "Ad"
msgstr "Anúncio"

msgid "Ad body is required"
msgstr "O corpo do anúncio é obrigatório"

msgid "Ad body must be less than 60 characters"
msgstr "O corpo do anúncio deve ter menos de 60 caracteres"

msgid "Ad description is required"
msgstr "A descrição do anúncio é obrigatória"

msgid "Ad format"
msgstr "Formato do anúncio"

msgid "Ad Format"
msgstr "Formato do Anúncio"

msgid "Ad image dimensions are required"
msgstr "As dimensões da imagem do anúncio são obrigatórias"

msgid "Ad Name is required"
msgstr "O nome do anúncio é obrigatório"

msgid "Ad Set"
msgstr "Conjunto de Anúncios"

msgid "Ad set name"
msgstr "Nome do conjunto de anúncios"

msgid "Ad Sets"
msgstr "Conjuntos de Anúncios"

msgid "Ad sets must have at least one ad"
msgstr "Conjuntos de anúncios devem ter pelo menos um anúncio"

msgid "Ad title is required"
msgstr "O título do anúncio é obrigatório"

msgid "Ad title must be less than 30 characters"
msgstr "O título do anúncio deve ter menos de 30 caracteres"

msgid "Ad title must be less than 90 characters"
msgstr "O título do anúncio deve ter menos de 90 caracteres"

msgid "Ad type is required"
msgstr "O tipo de anúncio é obrigatório"

msgid "Ad type must be notification or news"
msgstr "O tipo de anúncio deve ser notificação ou notícia"

msgid "Add an existing ad"
msgstr "Adicionar um anúncio existente"

msgid "Add Conversion tracking"
msgstr "Adicionar rastreamento de conversão"

msgid "Additional Details"
msgstr "Detalhes Adicionais"

msgid "Ads"
msgstr "Anúncios"

#~ msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously approved ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
#~ msgstr "Anúncios são blocos de construção modulares que podem ser combinados com conjuntos de anúncios para criar combinações únicas. Seus anúncios previamente aprovados serão exibidos aqui. Selecione usando a caixa ao lado do nome. Use o botão \"Seleção Completa\" para concluir."

msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously created ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
msgstr "Anúncios são blocos de construção modulares que podem ser combinados com conjuntos de anúncios para criar combinações únicas. Seus anúncios previamente criados serão exibidos aqui. Selecione usando a caixa ao lado do nome. Use o botão \"Seleção Completa\" para concluir."

#~ msgid "Ads Help Center"
#~ msgstr "Central de Ajuda de Anúncios"

msgid "Advertiser Name"
msgstr "Nome do Anunciante"

msgid "Advertiser profile"
msgstr "Perfil do anunciante"

msgid "All OS"
msgstr "Todos os SO"

msgid "All time"
msgstr "Desde o início"

msgid "An ad from my Brave browser"
msgstr "Um anúncio do meu navegador Brave"

msgid "An error has occurred while processing your request."
msgstr "Ocorreu um erro ao processar sua solicitação."

msgid "and no additional charges will be incurred."
msgstr ""

msgid "and you will be charged {amountToCharge} USD."
msgstr ""

#~ msgid "and you will not be charged."
#~ msgstr ""

msgid "Android"
msgstr "Android"

msgid "API keys are used to get data from the reporting endpoints. They are unique to you."
msgstr "As chaves de API são utilizadas para acessar dados nos endpoints de relatório. Elas são exclusivas para você."

msgid "Are you sure you want to activate"
msgstr "Tem certeza de que deseja ativar?"

msgid "Are you sure you want to modify \"{creativeName}\"?"
msgstr "Tem certeza de que deseja modificar \"{creativeName}\"?"

msgid "Are you sure you want to pause"
msgstr "Tem certeza de que deseja pausar?"

#~ msgid "As effective as Google"
#~ msgstr "Tão eficaz quanto o Google"

msgid "At a marketing/media conference"
msgstr "Em uma conferência de marketing/mídia"

msgid "At least one country must be targeted"
msgstr "Pelo menos um país deve ser direcionado"

msgid "At least one platform must be targeted"
msgstr "Pelo menos uma plataforma deve ser direcionada"

msgid "Audiences"
msgstr "Públicos-alvo"

msgid "Augment your marketing strategy"
msgstr "Aprimore a sua estratégia de marketing"

msgid "Automatic interest targeting"
msgstr "Direcionamento automático por interesse"

msgid "Back"
msgstr "Voltar"

msgid "Basic Attention Token"
msgstr "Basic Attention Token"

msgid "BAT holders can now also cash in their tokens to advertise their companies or products on the Brave Ads network."
msgstr "Os detentores de BAT agora também podem trocar os seus tokens para anunciar as suas empresas ou produtos na rede de Anúncios Brave."

msgid "Billing Type"
msgstr "Tipo de Cobrança"

msgid "Body"
msgstr "Corpo"

msgid "Body Preview"
msgstr "Pré-visualização do Corpo"

msgid "Brave Ads deliver at the browser or search level, in dedicated placements that give your brand more spotlight and better engagement."
msgstr "Os Anúncios Brave são apresentados no navegador ou na pesquisa, em locais dedicados que dão à sua marca mais destaque e melhor engajamento."

msgid "Brave cannot recover this key, which has been generated in your browser. You will need to confirm this private key on the next step before changes are saved."
msgstr "O Brave não pode recuperar esta chave que foi gerada em seu navegador. Você precisará confirmar esta chave privada na próxima etapa antes que as alterações sejam salvas."

msgid "Brave is an independent company dedicated to building a user-first Web. Our private, feature-rich browser and search engine are used by 70+ million people worldwide, with hundreds of thousands more downloading every day."
msgstr "O Brave é uma empresa independente dedicada a construir uma Web centrada no usuário. Nosso navegador privado e rico em recursos, junto ao nosso mecanismo de busca, são utilizados por mais de 70 milhões de pessoas em todo o mundo, com centenas de milhares de novos downloads todos os dias."

msgid "Brave is where early adopters thrive. We’re celebrating our crypto roots by offering new and returning Brave Ads customers half off their first self-managed campaign if they buy with BAT<0>*</0>"
msgstr "Brave é o lugar onde os adotantes iniciais prosperam. Estamos celebrando nossas origens no mundo cripto oferecendo aos novos e antigos clientes dos Anúncios Brave 50% de desconto na primeira campanha autogerenciada se pagarem com BAT<0>*</0>"

msgid "Brave Search Ads"
msgstr "Anúncios da Pesquisa Brave"

#~ msgid "Brave Search ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
#~ msgstr ""

msgid "Brave Search Ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
msgstr ""

#~ msgid "Brave Search ads are built to perform and convert as well as - or better than - Google search ad clicks."
#~ msgstr ""

msgid "Brave Search Ads are built to perform and convert as well as - or better than - Google Search Ad clicks."
msgstr ""

#~ msgid "Brave Search Ads are privacy-preserving, text-based ads that appear at the top of a user’s search results page (SERP). Want to redefine your SEM program, and diversify from Big Tech? Get the first-mover advantage and start targeting your brand’s most important keywords today."
#~ msgstr "Os anúncios da Pesquisa Brave são anúncios que preservam a privacidade, baseados em texto e que aparecem no topo da página de resultados de busca (SERP) do usuário. Quer redefinir seu programa de SEM e se distanciar das Big Techs? Aproveite a vantagem do pioneirismo e comece a direcionar as palavras-chave mais importantes da sua marca hoje mesmo."

#~ msgid "Brave Search Ads can reach audiences who use ad blockers, and don’t use traditional search engines."
#~ msgstr "Os anúncios da Pesquisa Brave podem alcançar públicos que utilizam bloqueadores de anúncios e não usam mecanismos de busca tradicionais."

#~ msgid "Brave Search Ads convert as well as—or better than—Google Search ad clicks."
#~ msgstr "Os anúncios da Pesquisa Brave convertem tão bem quanto, ou até melhor, que os anúncios da Pesquisa Google."

#~ msgid "Brave Search is the fastest growing independent search engine since Bing, with more than 9 billion searches each year (and growing). It’s the default search engine for Brave’s tens of millions of worldwide users, and available in any other browser at <0>search.brave.com</0>"
#~ msgstr "A Pesquisa Brave é o mecanismo de busca independente de crescimento mais rápido desde o Bing, com mais de 9 bilhões de buscas a cada ano (e crescendo). É o mecanismo de busca padrão para dezenas de milhões de usuários do Brave em todo o mundo e está disponível em qualquer outro navegador em <0>search.brave.com</0>"

msgid "Brave’s 70+ million cord cutters, and tech savvy early adopters want privacy and performance over tracking and lag. Hundreds of thousands more make the switch every day. Want them to see your brand? Start your first campaign in minutes, and with as little as $500."
msgstr "Os mais de 70 milhões de usuários do Brave, que abandonaram a TV a cabo e são ligados em tecnologia, valorizam privacidade e desempenho em vez de serem rastreados e enfrentarem lentidão. Centenas de milhares de pessoas aderem à plataforma todos os dias. Quer que eles vejam sua marca? Comece sua primeira campanha em minutos, com um investimento inicial de apenas $500."

msgid "Budget"
msgstr "Orçamento"

msgid "Business name"
msgstr "Nome da Empresa"

msgid "Business name is required"
msgstr "O nome da empresa é obrigatório"

msgid "Business website"
msgstr "Site da Empresa"

msgid "Business Website is required"
msgstr "O site da empresa é obrigatório"

msgid "Call To Action"
msgstr "Chamada para Ação"

msgid "Call to action is required"
msgstr "A chamada para ação é obrigatória"

msgid "Call to action must be less than 15 characters"
msgstr "A chamada para ação deve ter menos de 15 caracteres"

msgid "Call to Action text"
msgstr "Texto da Chamada para Ação"

msgid "Campaign"
msgstr "Campanha"

msgid "Campaign creation is currently unavailable"
msgstr "A criação da campanha não está disponível no momento"

msgid "Campaign does not exist, or cannot be edited. Please try again later."
msgstr "A campanha não existe ou não pode ser editada. Por favor, tente novamente mais tarde."

msgid "Campaign Drafts"
msgstr "Rascunhos de Campanha"

msgid "Campaign Format is required"
msgstr "O Formato da Campanha é obrigatório"

msgid "Campaign has not started yet"
msgstr "A campanha ainda não começou"

msgid "Campaign Name"
msgstr "Nome da Campanha"

msgid "Campaign Name is required"
msgstr "O nome da campanha é obrigatório"

msgid "Campaign Settings"
msgstr "Configurações da Campanha"

msgid "Campaign Summary"
msgstr "Resumo da campanha"

msgid "Campaigns"
msgstr "Campanhas"

msgid "Cancel"
msgstr "Cancelar"

msgid "cannot create api key"
msgstr "não é possível criar a chave de API"

msgid "cannot create session"
msgstr "não é possível criar sessão"

msgid "Check eligibility"
msgstr "Verificar elegibilidade"

#~ msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
#~ msgstr ""

msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search Ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
msgstr ""

msgid "Check your brand's eligibility"
msgstr ""

msgid "Check your spam folder or <0>return to the login page</0> to try again."
msgstr ""

msgid "Choose"
msgstr "Escolher"

msgid "Choose a format for the campaign you would like to run."
msgstr "Escolha um formato para a campanha que você gostaria de executar."

msgid "Choose File"
msgstr "Escolha o Arquivo"

msgid "City"
msgstr "Cidade"

msgid "City is required"
msgstr "A cidade é obrigatória"

msgid "Click Here!"
msgstr "Clique Aqui!"

msgid "Click on the secure login link in the email to access your Brave Ads account."
msgstr "Clique no link seguro de login no seu e-mail para acessar sua conta dos Anúncios Brave."

msgid "Click the continue button below to complete the login process."
msgstr ""

msgid "Click Through Rate"
msgstr "Taxa de Cliques"

msgid "Click-through Conversions"
msgstr "Conversões por Cliques"

msgid "Clicks"
msgstr "Cliques"

msgid "Clone"
msgstr "Clonar"

msgid "Clone {campaignName}"
msgstr "Clonar {campaignName}"

msgid "Clone campaign: \"{campaignName}\"?"
msgstr "Clonar campanha: \"{campaignName}\"?"

msgid "Cloning a campaign will create a new draft campaign that inherits all properties of the original campaign, including ad sets. The new campaign will reuse any existing ads from the original campaign."
msgstr ""

#~ msgid "Cloning a campaign will take all properties including ad sets and ads, and create a new draft campaign with them."
#~ msgstr "Clonar uma campanha vai copiar todas as configurações, incluindo conjuntos de anúncios e anúncios, e criar um novo rascunho de campanha com elas."

msgid "Close"
msgstr "Fechar"

msgid "Close Campaign"
msgstr ""

msgid "Closing a campaign will immediately stop it from running. Once it has stopped running, any remaining funds will be transferred back to your account in 24-48 hours."
msgstr ""

msgid "Complete"
msgstr "Concluir"

msgid "Complete selection"
msgstr "Seleção concluída"

msgid "Complete your business profile to continue"
msgstr "Complete o perfil da sua empresa para continuar"

msgid "Congratulations! 🎉"
msgstr "Parabéns! 🎉"

msgid "Contact"
msgstr "Contato"

msgid "Contact sales"
msgstr "Contate o time de vendas"

msgid "Contact Sales"
msgstr "Contate o Time de Vendas"

msgid "Content"
msgstr "Conteúdo"

msgid "Continue"
msgstr "Continuar"

msgid "Conversion"
msgstr "Conversão"

msgid "Conversion Observation Window"
msgstr "Janela de Observação de Conversão"

msgid "Conversion Rate"
msgstr "Taxa de Conversão"

#~ msgid "Conversion reporting change"
#~ msgstr "Mudança no relatório de Conversão"

#~ msgid "Conversion reporting for Brave Ads has changed to better uphold user privacy and security. Campaigns with conversion URLs that violate the newly clarified requirements for conversion URLs will now automatically be paused until their URLs have been updated. Please review the guidelines and take any necessary action to prevent campaign disruption. New guidelines can be found at the <0/>"
#~ msgstr "A geração de relatórios de conversão para os Anúncios Brave foi alterada para melhor manter a privacidade e a segurança do usuário. Campanhas com URLs de conversão que violem os requisitos recentemente esclarecidos para URLs de conversão serão automaticamente pausadas até que suas URLs sejam atualizadas. Por favor, reveja as diretrizes e tome as ações necessárias para evitar a interrupção da campanha. As novas diretrizes podem ser encontradas em <0/>"

msgid "Conversion type must be Post Click"
msgstr ""

#~ msgid "Conversion type must be Post Click or Post View"
#~ msgstr "O tipo de conversão deve ser Pós-Clique ou Pós-Visualização"

msgid "Conversion type required."
msgstr "Tipo de conversão obrigatório."

msgid "Conversion URL must end in trailing asterisk (*)"
msgstr "A URL de conversão deve terminar com um asterisco (*)"

msgid "Conversion URL must not contain any whitespace"
msgstr "A URL de conversão não deve conter espaços em branco"

msgid "Conversion URL must start with https://"
msgstr "A URL de conversão deve começar com https://"

msgid "Conversion URL Pattern"
msgstr "Padrão de URL de Conversão"

msgid "Conversion URL required."
msgstr "A URL de conversão é obrigatória."

msgid "Conversions"
msgstr "Conversões"

msgid "Convert customers with AI-powered search ads"
msgstr ""

msgid "Copy this and keep this safe!"
msgstr "Copie isso e guarde em um local seguro!"

msgid "Cost per Acquisition"
msgstr "Custo por Aquisição"

msgid "Cost per click"
msgstr "Custo por clique"

msgid "Could not logout at this time. Please try again later."
msgstr "Não foi possível fazer logout no momento. Por favor, tente novamente mais tarde."

msgid "Country"
msgstr "País"

msgid "Country is required"
msgstr "O país é obrigatório"

msgid "Country Targeting"
msgstr "Direcionamento por País"

msgid "CPA"
msgstr "CPA"

msgid "CPC (Clicks)"
msgstr "CPC (Cliques)"

msgid "CPM (Impressions)"
msgstr "CPM (Visualizações)"

msgid "CPSV (Site Visits)"
msgstr "CPSV (Visitas ao Site)"

msgid "Create & Submit For Approval"
msgstr "Criar e Enviar Para Aprovação"

msgid "Create ad"
msgstr "Criar Anúncio"

msgid "Create new ad"
msgstr "Criar Novo Anúncio"

msgid "Create new keypair?"
msgstr "Criar Novo Par de Chaves?"

msgid "create now"
msgstr ""

msgid "created"
msgstr "criado"

msgid "CTR"
msgstr "CTR (Taxa de Cliques)"

msgid "Custom"
msgstr "Personalizado"

msgid "Daily"
msgstr "Diário"

msgid "Dashboard"
msgstr "Dashboard"

msgid "Data not valid for this private key"
msgstr "Dados inválidos para esta chave privada"

msgid "Decrypt Conversion Data?"
msgstr "Descriptografar Dados de Conversão?"

msgid "Define how you want your campaign to run."
msgstr "Defina como deseja que sua campanha seja executada."

msgid "Define the number of days Brave will observe conversions and attribute them to the campaign."
msgstr "Defina o número de dias que o Brave observará conversões e as atribuirá à campanha."

msgid "Disabled because you haven’t made any changes"
msgstr "Desativado por falta de alterações"

msgid "Disabled due to validation errors"
msgstr "Desativado devido a erros de validação"

msgid "Discard ad"
msgstr "Descartar Anúncio"

msgid "Discard campaign"
msgstr "Descartar Campanha"

msgid "Dismissal Rate"
msgstr "Taxa de Dispensas"

msgid "Dismissals"
msgstr "Dispensas"

msgid "Documentation can be found <0>here</0> on how to use the API."
msgstr "A documentação sobre como utilizar a API pode ser encontrada <0>aqui</0>."

msgid "Domain"
msgstr "Domínio"

msgid "Domain is required"
msgstr "O domínio é obrigatório"

msgid "Domain must not contain any whitespace"
msgstr "O domínio não deve conter espaços em branco"

msgid "Domain to advertise"
msgstr "Domínio para anunciar"

msgid "Don’t see the email?"
msgstr ""

#~ msgid "Don’t see the email? Check your spam folder or <0>try again.</0>"
#~ msgstr "Não recebeu o e-mail? Verifique sua pasta de spam ou <0>tente novamente.</0>"

#~ msgid "Don't show this message again"
#~ msgstr ""

msgid "Download Report"
msgstr "Baixar Relatório"

msgid "Downvotes"
msgstr "Votos Negativos"

msgid "Draft"
msgstr "Rascunho"

msgid "Drafts"
msgstr "Rascunhos"

msgid "Edit"
msgstr "Editar"

msgid "Edit {campaignName}"
msgstr "Editar {campaignName}"

msgid "Edit ad"
msgstr "Editar anúncio"

msgid "Email"
msgstr "E-mail"

msgid "Email address is required"
msgstr "Endereço de e-mail é obrigatório"

msgid "End"
msgstr "Fim"

msgid "End Date"
msgstr "Data Final"

msgid "End date is required"
msgstr "A data final é obrigatória"

msgid "End date must be after start date"
msgstr "A data final deve ser após a data de início"

msgid "End Time"
msgstr "Horário de término"

msgid "Enter a URL that indicates a desired action you want to measure, like a subscription or purchase confirmation page. Brave will count unique visits to that page as conversions if a user has seen or clicked your ad."
msgstr "Insira uma URL que indique uma ação desejada que você deseja medir, como uma página de confirmação de assinatura ou compra. O Brave contará visitas únicas a essa página como conversões se um usuário tiver visto ou clicado no seu anúncio."

msgid "Enter Ads Manager"
msgstr "Acessar o Gerenciador de Anúncios"

msgid "Enter your email address to get a secure login link. Use this link to access your Brave Ads account."
msgstr "Insira seu e-mail para obter um link seguro de login. Use este link para acessar sua conta dos Anúncios Brave."

msgid "Escape the low CTR of display banners"
msgstr "Fuja do baixo CTR dos banners de exibição"

msgid "Example - https://brave.com/brave-rewards/"
msgstr "Exemplo - https://brave.com/brave-rewards/"

msgid "Export"
msgstr "Exportar"

msgid "Failed to Fetch"
msgstr "Falha ao buscar"

msgid "Failed to load image"
msgstr "Falha ao carregar imagem"

#~ msgid "Failed to update profile: {0}"
#~ msgstr "Falha ao atualizar o perfil: {0}"

msgid "Failed to update profile: {errorMessage}"
msgstr ""

msgid "Failed to upload image"
msgstr "Falha ao enviar imagem"

msgid "Feedback"
msgstr "Feedback"

msgid "File upload complete for \"{fileName}\""
msgstr "Upload de arquivo completo para \"{fileName}\""

msgid "Filter ads by name..."
msgstr "Filtrar anúncios por nome..."

msgid "Finalize & submit"
msgstr "Finalizar e submeter"

msgid "First party tracking acknowledgement is required"
msgstr "É necessário concordar com o rastreamento interno do site"

msgid "For businesses of all sizes. Create your campaign in minutes, starting with as little as $500 per month."
msgstr "Para negócios de todos os tamanhos. Crie sua campanha em minutos, começando com apenas $500 por mês."

msgid "Format"
msgstr "Formato"

msgid "From"
msgstr "De"

msgid "From a Brave sales representative"
msgstr "De um representante de vendas do Brave"

msgid "From a friend/colleague"
msgstr "De um amigo/colega"

msgid "From an influential person I follow online"
msgstr "De uma pessoa influente que sigo online"

msgid "Full Landing Page URL"
msgstr "URL Completa da Página de Destino"

msgid "Full name"
msgstr "Nome completo"

msgid "Full Name"
msgstr "Nome Completo"

msgid "Full name is required"
msgstr "O nome completo é obrigatório"

msgid "General Ad Inquiries"
msgstr "Dúvidas Gerais sobre Anúncios"

msgid "Generate"
msgstr "Gerar"

msgid "Generate a keypair for your account. Brave Ads will use your account’s public key to sign and encrypt conversion data. Only your organization will have access to the private key, which can be used to decrypt and view conversion data."
msgstr "Gere um par de chaves para sua conta. Os Anúncios Brave usarão a chave pública da sua conta para assinar e criptografar dados de conversão. Apenas a sua organização terá acesso à chave privada, que pode ser usada para descriptografar e visualizar dados de conversão."

msgid "Generate a new API key?"
msgstr "Gerar uma nova chave de API?"

msgid "Generate API Key"
msgstr "Gerar Chave de API"

msgid "Generating a new API key will result in the deactivation of your previous key, rendering it unusable for future requests. Make sure to update your code with the new key to avoid disruptions in your application’s functionality."
msgstr "Gerar uma nova chave de API resultará na desativação da sua chave anterior, tornando-a inutilizável para futuras solicitações. Certifique-se de atualizar seu código com a nova chave para evitar interrupções na funcionalidade de sua aplicação."

msgid "Get a free testing period"
msgstr ""

msgid "Get in touch with our team"
msgstr "Entre em contato como o nosso time"

msgid "Get in touch."
msgstr ""

msgid "Get incremental traffic with paid ads on the world's fastest growing search engine."
msgstr ""

msgid "Get login link"
msgstr "Obter o link de login"

msgid "Get started"
msgstr "Começar"

msgid "Get to your first million users with powerful ad placements on the world’s fastest-growing alternative browser & search engine."
msgstr "Alcance seu primeiro milhão de usuários com anúncios potentes no navegador e mecanismo de busca alternativo que mais cresce no mundo."

msgid "Have specific questions?"
msgstr ""

msgid "Help Center"
msgstr "Central de Ajuda"

msgid "Hi"
msgstr "Olá"

msgid "Hourly"
msgstr "Por hora"

msgid "I have reviewed and agree to the <0>Advertiser Privacy Policy</0> and platform <1>Terms of Service</1> applicable to Brave Ads."
msgstr "Eu li e concordo com a <0>Política de Privacidade do Anunciante</0> e os <1>Termos de Serviço</1> da plataforma que se aplicam aos Anúncios Brave."

msgid "I understand that first-party analytics are necessary for independent reporting."
msgstr "Entendo que é necessário usar análises internas para relatórios independentes."

msgid "I understand that I can only run ad campaigns in English, Spanish, and Portuguese."
msgstr "Entendo que só posso executar campanhas publicitárias em inglês, espanhol e português."

msgid "I understand that payment is required before ad campaigns can be launched."
msgstr "Entendo que o pagamento é necessário antes que as campanhas possam ser lançadas."

msgid "I would like to receive marketing emails about new features and promotions from Brave Ads"
msgstr "Gostaria de receber e-mails sobre novos recursos e promoções dos Anúncios Brave"

msgid "Id"
msgstr "Id"

msgid "Image"
msgstr "Imagem"

msgid "Image failed to load"
msgstr "Falha ao carregar imagem"

msgid "Image URL must be hosted on our private CDN"
msgstr "A URL da imagem deve estar hospedada em nosso CDN privado"

msgid "Image URL must be valid"
msgstr "A URL da imagem deve ser válida"

msgid "Images"
msgstr "Imagens"

msgid "Impressions"
msgstr "Visualizações"

msgid "In a blog post/news article"
msgstr "Em uma postagem de blog/artigo de notícias"

msgid "In a newsletter I subscribe to"
msgstr "Em uma newsletter que sou inscrito"

msgid "In a post by Brave on LinkedIn"
msgstr "Em uma postagem do Brave no LinkedIn"

msgid "In a video on YouTube"
msgstr "Em um vídeo no YouTube"

msgid "In your local time this is"
msgstr "No seu horário local, isso é"

msgid "Include campaigns that ended over 6 months ago"
msgstr "Incluir campanhas que terminaram há mais de 6 meses"

msgid "Increase Budget"
msgstr ""

msgid "Increase budget for campaign: <0>{campaignName}</0>?"
msgstr ""

#~ msgid "Incremental"
#~ msgstr "Incremental"

msgid "Invalid format"
msgstr "Formato inválido"

msgid "invalid session"
msgstr "sessão inválida"

msgid "Invalid Session"
msgstr "Sessão Inválida"

msgid "Invalid Token"
msgstr "Token Inválido"

msgid "iOS"
msgstr "iOS"

msgid "is not"
msgstr "não é"

msgid "It is a modern <0>advertiser-first</0> ad platform made to help brands reach new users. Brave Ads are discrete, unobtrusive first-party ads built directly into the browser and search engine, with familiar formats like push notifications and news-feed ads. Brave’s innovative on-device targeting respects a user’s desire for privacy while still delivering personalized ads based on their interests, which drives high ROI for advertisers."
msgstr "É uma plataforma de publicidade moderna, <0>focada nos anunciantes</0>, projetada para ajudar as marcas a alcançarem novos usuários. Os Anúncios Brave são discretos, não intrusivos e são anúncios próprios integrados diretamente no navegador e no mecanismo de busca, com formatos familiares como notificações push e anúncios no feed de notícias. O inovador direcionamento no dispositivo do Brave respeita o desejo do usuário por privacidade, ao mesmo tempo em que oferece anúncios personalizados com base em seus interesses, o que gera um alto retorno sobre o investimento para os anunciantes."

msgid "It is scheduled to start {campaignStartFromNow}."
msgstr "O seu início está agendado para {campaignStartFromNow}."

msgid "Key copied"
msgstr "Chave copiada"

msgid "Keypairs"
msgstr "Pares de chaves"

msgid "Language acknowledgement is required"
msgstr "É necessário confirmar o idioma"

msgid "Last 30 days"
msgstr "Últimos 30 dias"

msgid "Last 7 days"
msgstr "Últimos 7 dias"

msgid "Last month"
msgstr "Último mês"

msgid "Learn More"
msgstr "Saiba Mais"

msgid "Let Brave pick categories for me."
msgstr "Deixe o Brave escolher categorias para mim."

msgid "Lifetime Budget"
msgstr "Orçamento Vitalício"

msgid "Lifetime budget is required"
msgstr "O orçamento vitalício é obrigatório"

msgid "Lifetime budget must be ${MIN_PER_CAMPAIGN} or more"
msgstr "O orçamento vitalício deve ser de ${MIN_PER_CAMPAIGN} ou mais"

msgid "Lifetime budget must be higher for date range provided. Minimum ${min}."
msgstr "O orçamento vitalício deve ser maior para o intervalo de datas fornecido. Mínimo ${min}."

msgid "Like your campaign's performance? Increase the budget to expand its reach and connect with more potential customers. Specify below how much you would like to add to your current budget of <0>{previousBudget}</0>"
msgstr ""

msgid "Linux"
msgstr "Linux"

msgid "Locations"
msgstr "Localizações"

msgid "Log in"
msgstr "Entrar"

msgid "Log into your Brave Ads account"
msgstr "Faça login em sua conta dos Anúncios Brave"

#~ msgid "Logging in"
#~ msgstr "Fazendo login"

msgid "Logout"
msgstr "Sair"

msgid "macOS"
msgstr "macOS"

msgid "Make payment & submit for approval"
msgstr "Efetue o pagamento e envie para aprovação"

msgid "Make sure to include your brand name in the title OR body of the ad. Otherwise, it will be rejected."
msgstr "Certifique-se de incluir o nome da sua marca no título OU no corpo do anúncio. Caso contrário, ele será rejeitado."

msgid "Max 30 Characters"
msgstr "Máx. 30 caracteres"

msgid "Max 60 Characters"
msgstr "Máx. 60 caracteres"

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved."
msgstr "Modificar um anúncio o colocará imediatamente em revisão. Isso significa que não será mais exibido aos usuários até que seja aprovado."

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved. This ad is also shared across {campaignLength} campaigns. Any modifications made will be effective for all campaigns using this creative."
msgstr "Modificar um anúncio o colocará imediatamente em revisão. Isso significa que não será mais exibido aos usuários até que seja aprovado. Este anúncio também é compartilhado em {campaignLength} campanhas. Quaisquer modificações feitas serão eficazes para todas as campanhas que utilizam este criativo."

msgid "Modifying the state of this ad will effect more than one campaign:"
msgstr "Modificar o estado deste anúncio afetará mais de uma campanha:"

msgid "Name"
msgstr "Nome"

msgid "Name your ad set to distinguish it from others."
msgstr "Nomeie seu conjunto de anúncios para diferenciá-lo dos demais."

msgid "New"
msgstr "Novo"

msgid "New ad"
msgstr "Novo anúncio"

msgid "New API Key"
msgstr "Nova Chave de API"

msgid "New Campaign"
msgstr "Nova Campanha"

msgid "New campaigns typically take up to 48 hours to review during a regular U.S. business week."
msgstr "Novas campanhas geralmente demoram até 48 horas para serem revisadas durante uma semana de trabalho regular, equivalente a dois dias úteis, nos Estados Unidos."

msgid "New Keypair"
msgstr "Novo Par de Chaves"

msgid "New tab takeover"
msgstr "Novo destaque de aba"

msgid "New tab takeovers"
msgstr "Novos destaques de aba"

#~ msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services"
#~ msgstr ""

msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services."
msgstr ""

msgid "Newsfeed"
msgstr "Feed de notícias"

msgid "Newsfeed Ad"
msgstr "Anúncio no feed de notícias"

msgid "Newsfeed ads"
msgstr "Anúncios no feed de notícias"

msgid "Next"
msgstr "Próximo"

msgid "No"
msgstr "Não"

msgid "No {billingType} pricing available for {label}, contact selfserve@brave.com for help"
msgstr "Não há preços de {billingType} disponíveis para {label}, entre em contato com selfserve@brave.com para obter ajuda"

#~ msgid "no additional charge will be applied."
#~ msgstr ""

msgid "No conversion URL setup"
msgstr "Nenhuma configuração de URL de conversão"

msgid "No file result"
msgstr "Sem resultado de arquivo"

msgid "No previous ads available"
msgstr "Nenhum anúncio anterior disponível"

msgid "no session created"
msgstr "nenhuma sessão criada"

#~ msgid "Not automatically redirected? Click this link to go to the dashboard."
#~ msgstr "Não foi redirecionado automaticamente? Clique neste link para acessar o dashboard."

msgid "Notes"
msgstr "Notas"

msgid "Notification"
msgstr "Notificação"

msgid "Notification ad"
msgstr "Anúncio de notificação"

msgid "Notification ads"
msgstr "Anúncios de notificação"

msgid "Observation Window"
msgstr "Janela de Observação"

msgid "Observation window must be 1, 7, or 30 days."
msgstr "A janela de observação deve ser de 1, 7 ou 30 dias."

msgid "Observation window required."
msgstr "A janela de observação é necessária."

msgid "On / Off"
msgstr "Ligado / Desligado"

msgid "On a podcast I listen to"
msgstr "Em um podcast que eu ouço"

msgid "On/Off"
msgstr "Ligado/Desligado"

msgid "Once confirmed, your account’s keypair will be replaced with the new keypair."
msgstr "Uma vez confirmado, o par de chaves da sua conta será substituído pelo novo par de chaves."

#~ msgid "Or email <0>searchads@brave.com</0>"
#~ msgstr "Ou envie um e-mail para <0>searchads@brave.com</0>"

msgid "or sign in using a password"
msgstr "ou faça login usando uma senha"

msgid "or sign in using a secure link"
msgstr "ou faça login usando um link seguro"

msgid "OS"
msgstr "SO (Sistema Operacional)"

msgid "Other (please specify)"
msgstr "Outro (por favor, especifique)"

msgid "Other accounts"
msgstr "Outras contas"

msgid "Other source"
msgstr "Outra fonte"

msgid "Password"
msgstr "Senha"

msgid "Pause"
msgstr "Pausar"

msgid "Pay with BAT to get <0>50% off</0> your first self-managed campaign."
msgstr "Pague com BAT para obter <0>50% de desconto</0> na sua primeira campanha autogerenciada."

msgid "Payment"
msgstr "Pagamento"

msgid "Payment acknowledgement is required"
msgstr "É necessário confirmar o pagamento"

msgid "Payment is required before launching your campaign."
msgstr "Antes de lançar sua campanha, é necessário efetuar o pagamento."

msgid "Performance Report"
msgstr "Relatório de Desempenho"

msgid "Platforms"
msgstr "Plataformas"

msgid "Please ask your Account Manager for reports on campaigns of this format."
msgstr "Por favor, consulte o seu Gerente de Conta para obter relatórios sobre campanhas deste tipo."

msgid "Please confirm your account’s new private key:"
msgstr "Por favor, confirme a nova chave privada da sua conta:"

msgid "Please enter a username and password"
msgstr "Por favor, insira um nome de usuário e senha"

msgid "Please enter a valid amount"
msgstr ""

msgid "Please enter a valid domain, for example: brave.com"
msgstr "Por favor, insira um domínio válido, por exemplo: brave.com"

msgid "Please enter a valid email address"
msgstr "Por favor, insira um endereço de e-mail válido"

msgid "Please enter a valid URL, for example https://brave.com"
msgstr "Por favor, insira uma URL válida, por exemplo https://brave.com"

msgid "Please enter a valid URL, for example https://brave.com/product/*"
msgstr "Por favor, insira uma URL válida, por exemplo https://brave.com/product/*"

msgid "Please enter an email."
msgstr "Por favor, insira um e-mail."

msgid "Please let us know how you heard about Brave Ads"
msgstr "Por favor, nos diga como você descobriu os Anúncios Brave"

msgid "Please let us know why you're interested in Brave Ads"
msgstr "Por favor, nos diga por que você está interessado nos Anúncios Brave"

msgid "Please refresh the page to try again, or email <0/> for assistance."
msgstr "Por favor, atualize a página e tente novamente, ou envie um e-mail para <0/> para obter ajuda."

msgid "Please see our <0>Advertiser Privacy Policy</0> and <1>Terms of Service</1> applicable to Brave Ads."
msgstr "Por favor, consulte nossa <0>Política de Privacidade do Anunciante</0> e <1>Termos de Serviço</1> que se aplicam aos Anúncios Brave."

msgid "Please specify how you heard about Brave Ads"
msgstr "Por favor, nos diga como você ficou sabendo sobre os Anúncios Brave"

msgid "Preparing file for upload..."
msgstr "Preparando arquivo para upload..."

msgid "Price"
msgstr "Preço"

msgid "Price is a required field"
msgstr "Preço é um campo obrigatório"

msgid "Pricing"
msgstr "Preços"

msgid "Pricing Type"
msgstr "Tipo de Preço"

msgid "Pricing type is a required field"
msgstr "Tipo de preço é um campo obrigatório"

msgid "Pricing type must be CPM, CPC, or CPSV"
msgstr "O tipo de preço deve ser CPM, CPC ou CPSV"

msgid "Primary region of business"
msgstr "Principal região de negócios"

msgid "Primary region of business is required"
msgstr "A principal região de negócios é obrigatória"

msgid "Private Key"
msgstr "Chave Privada"

msgid "Proceed"
msgstr "Avançar"

msgid "Profile"
msgstr "Perfil"

msgid "Profile API Key"
msgstr "Chave de API do Perfil"

msgid "Profile Details"
msgstr "Detalhes do Perfil"

msgid "Query parameter"
msgstr "Parâmetro de consulta"

msgid "Query string parameters"
msgstr "Parâmetros de string de consulta"

msgid "Reach 70+ million highly engaged, otherwise unreachable users."
msgstr "Alcance mais de 70 milhões de usuários altamente engajados, que de outra forma seriam inalcançáveis."

msgid "Reach new audiences you're missing"
msgstr ""

msgid "Ready to reach your first million users? Start your first campaign today."
msgstr "Pronto para alcançar o seu primeiro milhão de usuários? Comece a sua primeira campanha hoje."

msgid "Remove"
msgstr "Remover"

msgid "Remove Conversion tracking"
msgstr "Remover rastreamento de Conversão"

#~ msgid "Request another link."
#~ msgstr "Solicitar outro link."

msgid "Return"
msgstr ""

msgid "Return to dashboard"
msgstr "Voltar ao dashboard"

msgid "Review"
msgstr "Revisão"

msgid "Review ads"
msgstr "Revisar anúncios"

#~ msgid "Risk Free"
#~ msgstr "Sem riscos"

#~ msgid "Risk free trial for up to 30 days"
#~ msgstr ""

msgid "Sample Queries"
msgstr "Amostras de Consultas"

msgid "Save"
msgstr "Salvar"

msgid "Save ad"
msgstr "Salvar anúncio"

msgid "Saving..."
msgstr "Salvando..."

msgid "Search homepage"
msgstr "Página inicial de pesquisa"

msgid "Search keyword"
msgstr "Palavra-chave de busca"

msgid "Search keyword ads"
msgstr "Anúncios de palavra-chave de busca"

msgid "searchads@brave.com"
msgstr ""

msgid "Select Account"
msgstr "Selecionar conta"

msgid "Select one campaign to clone"
msgstr "Selecione uma campanha para clonar"

msgid "Select one campaign to edit"
msgstr "Selecione uma campanha para editar"

msgid "Select the ads you would like to include in this ad set. Only checked ads are included."
msgstr "Selecione os anúncios que deseja incluir neste conjunto de anúncios. Apenas os anúncios marcados com \"check\" serão incluídos."

msgid "Select the audience segments to target"
msgstr "Selecione os segmentos de público-alvo"

msgid "Select the geographic regions where your ads will be shown."
msgstr "Selecione as regiões geográficas onde seus anúncios serão exibidos."

msgid "Select the interest segments and platforms you would like to target."
msgstr "Selecione os segmentos de interesse e plataformas que você deseja direcionar."

msgid "Select the platforms to target"
msgstr "Selecione as plataformas para direcionar"

msgid "Selected ads"
msgstr "Anúncios selecionados"

msgid "Set up a trial campaign in minutes."
msgstr ""

msgid "Setup Progress"
msgstr "Progresso da Configuração"

msgid "Simple ads to reach digital trend-setters around the world"
msgstr "Anúncios simples para alcançar os pioneiros digitais em todo o mundo"

msgid "Site Visit Rate"
msgstr "Taxa de Visitas ao Site"

msgid "Site visits"
msgstr "Visitas ao site"

msgid "Site Visits"
msgstr "Visitas ao Site"

msgid "Skip survey and continue"
msgstr "Pular pesquisa e continuar"

msgid "Something went wrong."
msgstr "Algo deu errado."

msgid "Spend"
msgstr "Gasto"

msgid "Sponsored"
msgstr "Patrocinado"

msgid "Start"
msgstr "Iniciar"

msgid "Start a campaign with BAT"
msgstr "Iniciar uma campanha com BAT"

msgid "Start Date"
msgstr "Data de Início"

msgid "Start Date is required"
msgstr "A data de Início é obrigatória"

msgid "Start Date must be minimum of 2 days from today"
msgstr "A data de Início deve ser no mínimo 2 dias a partir de hoje"

msgid "Start fast and experiment"
msgstr "Comece rápido e experimente"

msgid "Start Time"
msgstr "Hora de Início"

msgid "Start your company profile"
msgstr "Crie o perfil da sua empresa"

msgid "State"
msgstr "Estado"

msgid "Status"
msgstr "Status"

msgid "Street address"
msgstr "Endereço"

msgid "Street address is required"
msgstr "O endereço é obrigatório"

msgid "Submit"
msgstr "Enviar"

msgid "Success! Check your email."
msgstr "Sucesso! Verifique seu e-mail."

#~ msgid "Successfully logged in!"
#~ msgstr "Login realizado com sucesso!"

msgid "Support"
msgstr "Suporte"

msgid "Take our survey!"
msgstr "Responda nossa pesquisa!"

#~ msgid "Tap into more than <0>9 billion</0> annual Web searches"
#~ msgstr "Aproveite mais de <0>9 bilhões</0> de pesquisas anuais na Web"

msgid "Target URL"
msgstr "URL de Destino"

msgid "Target URL is a required field"
msgstr "URL de Destino é um campo obrigatório"

msgid "Target URL is not valid"
msgstr "A URL de Destino não é válida"

msgid "Target URL not validated"
msgstr "URL de Destino não validada"

msgid "Targeting"
msgstr "Direcionamento"

msgid "Tell us why you're interested in Brave Ads"
msgstr "Nos conte por que você está interessado nos Anúncios Brave"

msgid "Terms & Conditions acknowledgement is required"
msgstr "É necessário concordar com os Termos e Condições"

msgid "The <0>Basic Attention Token (BAT)</0> is Brave’s native crypto token. Brave Browser users who opt into <1>Brave Rewards</1> can earn BAT just for opting into viewing ads from the Brave Ads network. Earners can hold BAT like any other crypto asset, trade it, cash it in, or even donate it to their favorite creators."
msgstr "O <0>Basic Attention Token (BAT)</0> é a criptomoeda nativa do Brave. Usuários do navegador Brave que optam pelas <1>Recompensas Brave</1> podem ganhar BAT apenas por visualizar anúncios da rede dos Anúncios Brave. Os ganhadores podem manter o BAT como qualquer outro ativo cripto, trocá-lo, convertê-lo em dinheiro ou até mesmo doá-lo aos seus criadores favoritos."

msgid "The campaign will likely stop running before the end date provided due to budget constraints."
msgstr ""

#~ msgid "The current balance for your account that you can attribute to a new Campaign."
#~ msgstr ""

msgid "The current value for this metric may be an underestimate."
msgstr "O valor atual para esta métrica pode ser uma subestimativa."

msgid "The following query string parameters will be added to your landing page URLs. This will allow you to track the performance of your ads."
msgstr "Os seguintes parâmetros da string de consulta serão adicionados às URLs das suas páginas de destino. Isso permitirá que você acompanhe o desempenho dos seus anúncios."

msgid "The magic link you have requested has either expired or has already been used. Please return to the login page and try again."
msgstr ""

msgid "The username or password did not match our records."
msgstr "O nome de usuário ou senha não correspondem aos nossos registros."

msgid "This is a news display ad, it wll look like part of the news feed."
msgstr "Este é um anúncio de exibição de notícias, ele será integrado ao feed de notícias."

msgid "This key is unique to you, make sure to safely store it and avoid sharing it with others to prevent unauthorized access."
msgstr "Esta chave é exclusiva para você. Guarde-a com segurança e evite compartilhá-la com outras pessoas para evitar acessos não autorizados."

msgid "This month"
msgstr "Esse mês"

msgid "Time Zone"
msgstr "Fuso horário"

msgid "Title"
msgstr "Título"

msgid "Title Preview"
msgstr "Visualização do Título"

msgid "To"
msgstr "Para"

msgid "To protect user’s privacy, verified ad conversion data is encrypted so that the identities of converted users remain anonymous to Brave. You can decrypt the conversion data in the CSV file by providing your private key here. If no key is provided, you will receive the encrypted conversion data. Your private key will never be sent to or stored on any Brave servers."
msgstr "Para proteger a privacidade do usuário, os dados de conversão do anúncio verificado são criptografados, garantindo que as identidades dos usuários convertidos permaneçam anônimas para o Brave. Você pode descriptografar os dados de conversão no arquivo CSV fornecendo sua chave privada aqui. Se nenhuma chave for fornecida, você receberá os dados de conversão criptografados. Sua chave privada nunca será enviada ou armazenada em nenhum servidor do Brave."

msgid "Today"
msgstr "Hoje"

#~ msgid "Try a free one-month test to see how Brave Search Ads perform for your brand."
#~ msgstr "Faça um teste gratuito de um mês para ver o desempenho dos Anúncios de Pesquisa Brave para sua marca."

#~ msgid "Try a free one-month test to see how Brave Search Ads perform for your business."
#~ msgstr ""

msgid "Try Brave Search Ads risk-free to see how the ads perform for your website."
msgstr ""

msgid "Unable to clone campaign"
msgstr "Não foi possível clonar a campanha"

msgid "Unable to create a new campaign"
msgstr "Não foi possível criar uma nova campanha"

msgid "Unable to Create Campaign."
msgstr "Não foi possível criar a campanha."

msgid "Unable to create payment session. Please try again."
msgstr "Não foi possível criar uma sessão de pagamento. Por favor, tente novamente."

msgid "Unable to decrypt conversion data"
msgstr "Não foi possível descriptografar os dados de conversão"

msgid "Unable to download CSV"
msgstr "Não foi possível baixar o CSV"

msgid "Unable to get campaign information for ad"
msgstr "Não foi possível obter informações da campanha para o anúncio"

msgid "Unable to get profile information"
msgstr "Não foi possível obter informações do perfil"

msgid "Unable to load ad"
msgstr "Não foi possível carregar o anúncio"

#~ msgid "Unable to load reporting details for campaign: {0}"
#~ msgstr "Não foi possível carregar os detalhes do relatório para a campanha: {0}"

msgid "Unable to load reporting details for campaign: {campaignName}"
msgstr ""

#~ msgid "Unable to login, link has expired or has already been used."
#~ msgstr "Não foi possível fazer login, o link expirou ou já foi utilizado."

msgid "Unable to login."
msgstr ""

msgid "Unable to register your organization at this time. Please try again later."
msgstr "No momento, não foi possível cadastrar sua organização. Por favor, tente novamente mais tarde."

msgid "Unable to retrieve Campaign data."
msgstr "Não foi possível recuperar os dados da Campanha."

msgid "Unable to retrieve images"
msgstr "Não foi possível recuperar as imagens"

msgid "Unable to save ad"
msgstr "Não foi possível salvar o anúncio"

msgid "Unable to submit registration"
msgstr "Não foi possível enviar o cadastro"

msgid "Unable to update Advertiser."
msgstr "Não foi possível atualizar o Anunciante."

msgid "Unable to Update Campaign."
msgstr "Não foi possível atualizar a Campanha."

msgid "Unable to upload image"
msgstr "Não foi possível carregar a imagem"

msgid "Unexpected error validating your credentials. Please try again later."
msgstr "Erro inesperado ao validar suas credenciais. Por favor, tente novamente mais tarde."

msgid "Unknown"
msgstr "Desconhecido"

msgid "Update & Submit For Approval"
msgstr "Atualizar e Enviar Para Aprovação"

msgid "Upload"
msgstr "Carregar"

msgid "Upload Image"
msgstr "Enviar Imagem"

msgid "Upload new image"
msgstr "Enviar nova imagem"

msgid "Uploaded images can be shared across different ad sets within a Campaign. For best quality, upload images at 900x750px resolution. Images will be automatically scaled to this size."
msgstr "As imagens enviadas podem ser compartilhadas entre diferentes conjuntos de anúncios dentro de uma Campanha. Para obter a melhor qualidade, envie imagens com resolução de 900x750px. As imagens serão automaticamente dimensionadas para esse tamanho."

msgid "Uploading file..."
msgstr "Enviando arquivo..."

msgid "Upvotes"
msgstr "Curtidas"

msgid "URL is valid."
msgstr "A URL é válida."

msgid "URL must not contain any whitespace"
msgstr "A URL não deve conter espaços em branco"

msgid "URL must start with https://"
msgstr "A URL deve começar com https://"

msgid "URL Pattern"
msgstr "Padrão de URL"

msgid "URL should have a trailing asterisk - Example: https://brave.com/products/*"
msgstr "A URL deve ter um asterisco no final - Exemplo: https://brave.com/produtos/*"

msgid "Use existing ads"
msgstr "Usar anúncios existentes"

msgid "Validating URL..."
msgstr "Validando URL..."

msgid "Value"
msgstr "Valor"

msgid "Verified Conversions Report"
msgstr "Relatório de Conversões Verificadas"

msgid "View profile"
msgstr "Ver perfil"

msgid "View-through Conversions"
msgstr "Conversões por Visualização"

msgid "Visit Help Center"
msgstr "Visitar a Central de Ajuda"

msgid "We value your feedback and would love to hear your thoughts on your recent experience. <0/>Your input will help us improve the Brave Ads platform to better meet your needs. <1/>It only takes a few minutes and your participation will be greatly appreciated! <2/>Thank you for using Brave Ads! <3/>"
msgstr "Valorizamos seu feedback e adoraríamos ouvir suas opiniões sobre sua experiência recente. <0/>Sua contribuição nos ajudará a melhorar a plataforma dos Anúncios Brave para atender melhor às suas necessidades. <1/>Leva apenas alguns minutos e sua participação será muito importante! <2/>Obrigado por usar os Anúncios Brave! <3/>"

msgid "Website must not contain any whitespace"
msgstr "O site não deve conter espaços em branco"

msgid "Website must start with https://"
msgstr "O site deve começar com https://"

msgid "What is Brave Ads?"
msgstr "O que são os Anúncios Brave?"

msgid "What’s BAT?"
msgstr "O que é BAT?"

msgid "What’s Brave?"
msgstr "O que é Brave?"

msgid "Where did you hear about Brave Ads"
msgstr "Onde você ouviu falar sobre os Anúncios Brave"

msgid "While looking up alternatives to Google Ads"
msgstr "Enquanto procurava alternativas para o Google Ads"

msgid "Why use Brave Ads?"
msgstr "Por que usar os Anúncios Brave?"

msgid "Windows"
msgstr "Windows"

msgid "Work email"
msgstr "E-mail corporativo"

msgid "Yes"
msgstr "Sim"

msgid "You are about to close this campaign."
msgstr ""

msgid "You are attempting to create a new keypair, this will replace any of your account’s existing keypairs. Please note, previous keypairs cannot be retrieved or used once replaced."
msgstr "Você está tentando criar um novo par de chaves, isso substituirá qualquer um dos pares de chaves existentes na sua conta. Observe que os pares de chaves anteriores não podem ser recuperados ou usados uma vez que forem substituídos."

msgid "You are logging into the Brave Ads Manager Dashboard."
msgstr ""

#~ msgid "You have {0} errors that must be fixed before submitting."
#~ msgstr "Você tem {0} erros que devem ser corrigidos antes de enviar."

msgid "You have {errorCount} errors that must be fixed before submitting."
msgstr ""

msgid "You have unsaved changes"
msgstr "Você tem alterações não salvas"

msgid "You may have access to multiple accounts. Switch between them here."
msgstr "Você pode ter acesso a várias contas. Alterne entre elas aqui."

msgid "You will soon receive an email with the next steps of registration. While you wait, we recommend you check out our help center to get helpful information for getting started with Brave Ads."
msgstr "Em breve, você receberá um e-mail com os próximos passos do cadastro. Enquanto isso, sugerimos que você dê uma olhada em nossa Central de Ajuda para obter informações úteis sobre como começar a usar os Anúncios Brave."

#~ msgid "Your account has a balance of {0} USD. This will be applied to your campaign"
#~ msgstr ""

msgid "Your account has a balance of {accountBalance} USD. This will be applied to your campaign"
msgstr ""

#~ msgid "Your account has a balance of ${0} USD. This will be applied to your campaign"
#~ msgstr ""

msgid "Your account’s new private key will be:"
msgstr "A nova chave privada da sua conta será:"

msgid "Your account’s public key:"
msgstr "A chave pública da sua conta:"

msgid "Your campaign has been created and is now being reviewed by our ads team. <0/>We’ll be in contact as soon as your campaign is approved and activated."
msgstr "Sua campanha foi criada e está sendo revisada por nossa equipe de anúncios. <0/>Entraremos em contato assim que sua campanha for aprovada e ativada."

msgid "Your campaign has been updated! <0/>If you added new ads, we’ll be in contact as soon as they are approved and activated. <1/>Thank you for using Brave Ads!"
msgstr "Sua campanha foi atualizada! <0/>Se você adicionou novos anúncios, entraremos em contato assim que eles forem aprovados e ativados. <1/>Obrigado por usar os Anúncios Brave!"

msgid "Your current account balance"
msgstr ""

msgid "Your trial campaign will be reviewed by an Account Manager. Add any notes or questions for them here."
msgstr "Sua campanha de teste será revisada por um Gerente de Conta. Adicione aqui quaisquer observações ou perguntas para eles."

msgid "Zip / Postal Code"
msgstr "CEP"
