msgid ""
msgstr ""
"POT-Creation-Date: 2024-02-08 15:57-0500\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: es_419\n"
"Project-Id-Version: 27b1a082298d0ee642e56f654bcd7273\n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: 2024-09-05 17:42\n"
"Last-Translator: \n"
"Language-Team: Spanish, Latin America\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: 27b1a082298d0ee642e56f654bcd7273\n"
"X-Crowdin-Project-ID: 10\n"
"X-Crowdin-Language: es-419\n"
"X-Crowdin-File: /master/src/locales/en.po\n"
"X-Crowdin-File-ID: 16450\n"

msgid "{adFormatName} are not available for self-managed campaigns. Please contact sales to inquire."
msgstr "{adFormatName} no están disponibles para las campañas autogestionadas. Póngase en contacto con el departamento de ventas para consultar."

msgid "{billingType} price must be {price} or higher"
msgstr "{billingType} debe ser {price} o superior"

msgid "{campaignFormat} pricing configuration option(s):"
msgstr "{campaignFormat} opción(es) de configuración de precios:"

msgid "{length}/{maxLength} characters"
msgstr "{length}/{maxLength} caracteres"

msgid "{type} status cannot be updated"
msgstr "El estado de {type} no se puede actualizar."

msgid "*Limited time only. Available to new advertisers, and those who ran campaigns prior to September 30, 2023. Eligible for Push Notification and Newsfeed ads."
msgstr "*Solo por tiempo limitado. Disponible para nuevos anunciantes y para aquellos que hayan realizado campañas antes del 30 de septiembre de 2023. Elegible para anuncios de Notificación Push y fuente de noticias."

msgid "+{count} more"
msgstr "+{count} más"

msgid "1 Day"
msgstr "1 día"

msgid "30 Days"
msgstr "30 días "

msgid "401 Forbidden"
msgstr "401 prohibido"

msgid "7 Days"
msgstr "7 días"

msgid "A forbidden error was returned. You may need to log out and back in again."
msgstr "Se ha devuelto un error prohibido. Es posible que tenga que cerrar sesión y volver a iniciarla."

msgid "A login email is on the way"
msgstr "Un correo electrónico de inicio de sesión está en camino"

msgid "About Brave Ads"
msgstr "Acerca de Brave Ads"

msgid "Account"
msgstr "Cuenta"

msgid "Account Balance"
msgstr ""

msgid "Account Balance will be applied to this campaign"
msgstr ""

msgid "Account Settings"
msgstr "Configuración de la cuenta"

msgid "Activate"
msgstr "Activar"

msgid "Ad"
msgstr "Anuncio"

msgid "Ad body is required"
msgstr "El cuerpo del anuncio es obligatorio"

msgid "Ad body must be less than 60 characters"
msgstr "El cuerpo del anuncio debe tener menos de 60 caracteres"

msgid "Ad description is required"
msgstr "La descripción del anuncio es obligatoria"

msgid "Ad format"
msgstr "Formato del anuncio"

msgid "Ad Format"
msgstr "Formato del anuncio"

msgid "Ad image dimensions are required"
msgstr "Las dimensiones de la imagen del anuncio son obligatorias"

msgid "Ad Name is required"
msgstr "El nombre del anuncio es obligatorio"

msgid "Ad Set"
msgstr "Conjunto de anuncios"

msgid "Ad set name"
msgstr "Nombre del conjunto de anuncios"

msgid "Ad Sets"
msgstr "Conjuntos de anuncios"

msgid "Ad sets must have at least one ad"
msgstr "Los conjuntos de anuncios deben tener al menos un anuncio"

msgid "Ad title is required"
msgstr "El título del anuncio es obligatorio"

msgid "Ad title must be less than 30 characters"
msgstr "El título del anuncio debe tener menos de 30 caracteres"

msgid "Ad title must be less than 90 characters"
msgstr "El título del anuncio debe tener menos de 90 caracteres"

msgid "Ad type is required"
msgstr "El tipo de anuncio es obligatorio"

msgid "Ad type must be notification or news"
msgstr "El tipo de anuncio debe ser notificación o noticias"

msgid "Add an existing ad"
msgstr "Añadir un anuncio existente"

msgid "Add Conversion tracking"
msgstr "Añadir seguimiento de conversiones"

msgid "Additional Details"
msgstr "Detalles adicionales"

msgid "Ads"
msgstr "Anuncios"

#~ msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously approved ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
#~ msgstr "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously approved ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."

msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously created ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
msgstr "Los anuncios son bloques de construcción modulares que se pueden combinar con conjuntos de anuncios para crear combinaciones únicas. Los anuncios creados anteriormente se mostrarán aquí. Seleccione la casilla situada junto al nombre. Utilice el botón “Completar selección” para finalizar."

#~ msgid "Ads Help Center"
#~ msgstr "Ads Help Center"

msgid "Advertiser Name"
msgstr "Nombre del anunciante"

msgid "Advertiser profile"
msgstr "Perfil del anunciante"

msgid "All OS"
msgstr "Todos los SO"

msgid "All time"
msgstr "Todo el tiempo"

msgid "An ad from my Brave browser"
msgstr "Un anuncio de mi navegador Brave"

msgid "An error has occurred while processing your request."
msgstr "Se ha producido un error al procesar su solicitud."

msgid "and no additional charges will be incurred."
msgstr ""

msgid "and you will be charged {amountToCharge} USD."
msgstr ""

#~ msgid "and you will not be charged."
#~ msgstr ""

msgid "Android"
msgstr "Android"

msgid "API keys are used to get data from the reporting endpoints. They are unique to you."
msgstr "Las claves API se utilizan para obtener datos de los puntos de conexión de informes. Son únicas para usted."

msgid "Are you sure you want to activate"
msgstr "¿Está seguro(a) de que quiere activarlo?"

msgid "Are you sure you want to modify \"{creativeName}\"?"
msgstr "¿Está seguro(a) de que quiere modificar “{creativeName}”?"

msgid "Are you sure you want to pause"
msgstr "¿Está seguro(a) de que quiere pausar?"

#~ msgid "As effective as Google"
#~ msgstr "As effective as Google"

msgid "At a marketing/media conference"
msgstr "En una conferencia de marketing/medios de comunicación"

msgid "At least one country must be targeted"
msgstr "Debe dirigirse al menos a un país meta"

msgid "At least one platform must be targeted"
msgstr "Debe dirigirse al menos a una plataforma meta"

msgid "Audiences"
msgstr "Audiencias"

msgid "Augment your marketing strategy"
msgstr "Aumente su estrategia de marketing"

msgid "Automatic interest targeting"
msgstr "Segmentación automática de intereses"

msgid "Back"
msgstr "Atrás"

msgid "Basic Attention Token"
msgstr "Basic Attention Token"

msgid "BAT holders can now also cash in their tokens to advertise their companies or products on the Brave Ads network."
msgstr "Los poseedores de BAT ahora también pueden canjear sus tokens para anunciar sus empresas o productos en la red de Anuncios de Brave."

msgid "Billing Type"
msgstr "Tipo de facturación"

msgid "Body"
msgstr "Cuerpo"

msgid "Body Preview"
msgstr "Vista previa del cuerpo"

msgid "Brave Ads deliver at the browser or search level, in dedicated placements that give your brand more spotlight and better engagement."
msgstr "Los anuncios de Brave se entregan a nivel de navegador o de búsqueda, en ubicaciones dedicadas que le dan a su marca más protagonismo y una mejor participación."

msgid "Brave cannot recover this key, which has been generated in your browser. You will need to confirm this private key on the next step before changes are saved."
msgstr "Brave no puede recuperar esta clave, que se ha generado en su navegador. Deberá confirmar esta clave privada en el siguiente paso antes de guardar los cambios."

msgid "Brave is an independent company dedicated to building a user-first Web. Our private, feature-rich browser and search engine are used by 70+ million people worldwide, with hundreds of thousands more downloading every day."
msgstr "Brave es una compañía independiente dedicada a construir una Web centrada en el usuario. Nuestro navegador y motor de búsqueda privado y rico en funciones es utilizado por más de 70 millones de personas en todo el mundo, con cientos de miles más descargando cada día."

msgid "Brave is where early adopters thrive. We’re celebrating our crypto roots by offering new and returning Brave Ads customers half off their first self-managed campaign if they buy with BAT<0>*</0>"
msgstr "Brave es donde prosperan los adoptantes tempranos. Celebramos nuestras raíces criptográficas ofreciendo a los clientes nuevos y recurrentes de Brave Ads la mitad de su primera campaña autogestionada si compran con BAT<0>*</0>"

msgid "Brave Search Ads"
msgstr "Anuncios de Brave Search"

#~ msgid "Brave Search ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
#~ msgstr "Brave Search ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."

msgid "Brave Search Ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
msgstr "Los anuncios del Buscador de Brave están diseñados para ser fáciles de administrar. Una vez habilitados, las marcas se beneficiarán de un aumento pasivo en el tráfico sin la necesidad de monitorear y ajustar constantemente las campañas."

#~ msgid "Brave Search ads are built to perform and convert as well as - or better than - Google search ad clicks."
#~ msgstr "Brave Search ads are built to perform and convert as well as - or better than - Google search ad clicks."

msgid "Brave Search Ads are built to perform and convert as well as - or better than - Google Search Ad clicks."
msgstr "Los anuncios del Buscador de Brave están diseñados para funcionar y convertir tan bien o mejor que los clics de los anuncios de Google Search."

#~ msgid "Brave Search Ads are privacy-preserving, text-based ads that appear at the top of a user’s search results page (SERP). Want to redefine your SEM program, and diversify from Big Tech? Get the first-mover advantage and start targeting your brand’s most important keywords today."
#~ msgstr "Brave Search Ads are privacy-preserving, text-based ads that appear at the top of a user’s search results page (SERP). Want to redefine your SEM program, and diversify from Big Tech? Get the first-mover advantage and start targeting your brand’s most important keywords today."

#~ msgid "Brave Search Ads can reach audiences who use ad blockers, and don’t use traditional search engines."
#~ msgstr "Brave Search Ads can reach audiences who use ad blockers, and don’t use traditional search engines."

#~ msgid "Brave Search Ads convert as well as—or better than—Google Search ad clicks."
#~ msgstr "Brave Search Ads convert as well as—or better than—Google Search ad clicks."

#~ msgid "Brave Search is the fastest growing independent search engine since Bing, with more than 9 billion searches each year (and growing). It’s the default search engine for Brave’s tens of millions of worldwide users, and available in any other browser at <0>search.brave.com</0>"
#~ msgstr "Brave Search is the fastest growing independent search engine since Bing, with more than 9 billion searches each year (and growing). It’s the default search engine for Brave’s tens of millions of worldwide users, and available in any other browser at <0>search.brave.com</0>"

msgid "Brave’s 70+ million cord cutters, and tech savvy early adopters want privacy and performance over tracking and lag. Hundreds of thousands more make the switch every day. Want them to see your brand? Start your first campaign in minutes, and with as little as $500."
msgstr "Los más de 70 millones de usuarios de Brave, expertos en tecnología y adoptantes tempranos, prefieren la privacidad y el rendimiento sobre el rastreo y la lentitud. Cientos de miles más se cambian cada día. ¿Quiere que vean su marca? Inicie su primera campaña en minutos, con tan solo $500."

msgid "Budget"
msgstr "Presupuesto"

msgid "Business name"
msgstr "Nombre de la empresa"

msgid "Business name is required"
msgstr "Se requiere el nombre de la empresa."

msgid "Business website"
msgstr "Sitio web de la empresa"

msgid "Business Website is required"
msgstr "Se requiere el sitio web de la empresa."

msgid "Call To Action"
msgstr "Llamada a la acción"

msgid "Call to action is required"
msgstr "Se requiere una llamada a la acción."

msgid "Call to action must be less than 15 characters"
msgstr "La llamada a la acción debe tener menos de 15 caracteres."

msgid "Call to Action text"
msgstr "Texto de la llamada a la acción"

msgid "Campaign"
msgstr "Campaña"

msgid "Campaign creation is currently unavailable"
msgstr "La creación de campañas no está disponible actualmente"

msgid "Campaign does not exist, or cannot be edited. Please try again later."
msgstr "La campaña no existe o no se puede editar. Por favor, inténtelo de nuevo más tarde."

msgid "Campaign Drafts"
msgstr "Borradores de campaña"

msgid "Campaign Format is required"
msgstr "Se requiere el formato de la campaña"

msgid "Campaign has not started yet"
msgstr "La campaña aún no ha comenzado"

msgid "Campaign Name"
msgstr "Nombre de la campaña"

msgid "Campaign Name is required"
msgstr "Se requiere el nombre de la campaña"

msgid "Campaign Settings"
msgstr "Configuración de la campaña"

msgid "Campaign Summary"
msgstr "Resumen de la campaña"

msgid "Campaigns"
msgstr "Campañas"

msgid "Cancel"
msgstr "Cancelar"

msgid "cannot create api key"
msgstr "No se puede crear una clave de API"

msgid "cannot create session"
msgstr "No se puede crear una sesión"

msgid "Check eligibility"
msgstr "Verifique la elegibilidad"

#~ msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
#~ msgstr "Check if your brand is eligible for a <0>free trial</0> of Brave Search ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."

msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search Ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
msgstr "Compruebe si su marca es elegible para una <0>prueba gratuita</0> de anuncios del Buscador de Brave. <1>Pruebe el Buscador de Brave</1> para ver por qué nuestros usuarios nos eligen sobre Google y Bing."

msgid "Check your brand's eligibility"
msgstr "Compruebe la elegibilidad de su marca"

msgid "Check your spam folder or <0>return to the login page</0> to try again."
msgstr "Revise su carpeta de spam o <0>vuelva a la página de inicio de sesión</0> para intentarlo de nuevo."

msgid "Choose"
msgstr "Elegir"

msgid "Choose a format for the campaign you would like to run."
msgstr "Elija un formato para la campaña que desea ejecutar"

msgid "Choose File"
msgstr "Elija archivo"

msgid "City"
msgstr "Ciudad"

msgid "City is required"
msgstr "Se requiere ciudad"

msgid "Click Here!"
msgstr "¡Haga clic aquí!"

msgid "Click on the secure login link in the email to access your Brave Ads account."
msgstr "Haga clic en el enlace de inicio de sesión seguro del correo electrónico para acceder a su cuenta de Brave Ads."

msgid "Click the continue button below to complete the login process."
msgstr "Haga clic en el botón de continuar abajo para completar el proceso de inicio de sesión."

msgid "Click Through Rate"
msgstr "Clic por calificaciones"

msgid "Click-through Conversions"
msgstr "Conversiones por clics"

msgid "Clicks"
msgstr "Clics"

msgid "Clone"
msgstr "Clonar"

msgid "Clone {campaignName}"
msgstr "Clonar {campaignName}"

msgid "Clone campaign: \"{campaignName}\"?"
msgstr "Clonar campaña: “{campaignName}”?"

msgid "Cloning a campaign will create a new draft campaign that inherits all properties of the original campaign, including ad sets. The new campaign will reuse any existing ads from the original campaign."
msgstr ""

#~ msgid "Cloning a campaign will take all properties including ad sets and ads, and create a new draft campaign with them."
#~ msgstr "Al clonar una campaña, se tomarán todas las propiedades, incluidos los conjuntos de anuncios y los anuncios, y se creará un nuevo borrador de campaña con ellos."

msgid "Close"
msgstr "Cerrar"

msgid "Close Campaign"
msgstr ""

msgid "Closing a campaign will immediately stop it from running. Once it has stopped running, any remaining funds will be transferred back to your account in 24-48 hours."
msgstr ""

msgid "Complete"
msgstr "Completar"

msgid "Complete selection"
msgstr "Completar selección"

msgid "Complete your business profile to continue"
msgstr "Complete su perfil de empresa para continuar"

msgid "Congratulations! 🎉"
msgstr "¡Felicidades! 🎉"

msgid "Contact"
msgstr "Póngase en contacto con selfserve@brave.com"

msgid "Contact sales"
msgstr "Póngase en contacto con Ventas"

msgid "Contact Sales"
msgstr "Póngase en contacto con Ventas"

msgid "Content"
msgstr "Contenido"

msgid "Continue"
msgstr "Continuar"

msgid "Conversion"
msgstr "Conversión"

msgid "Conversion Observation Window"
msgstr "Ventana de observación de conversión"

msgid "Conversion Rate"
msgstr "Tasa de conversión"

#~ msgid "Conversion reporting change"
#~ msgstr "Conversion reporting change"

#~ msgid "Conversion reporting for Brave Ads has changed to better uphold user privacy and security. Campaigns with conversion URLs that violate the newly clarified requirements for conversion URLs will now automatically be paused until their URLs have been updated. Please review the guidelines and take any necessary action to prevent campaign disruption. New guidelines can be found at the <0/>"
#~ msgstr "Conversion reporting for Brave Ads has changed to better uphold user privacy and security. Campaigns with conversion URLs that violate the newly clarified requirements for conversion URLs will now automatically be paused until their URLs have been updated. Please review the guidelines and take any necessary action to prevent campaign disruption. New guidelines can be found at the <0/>"

msgid "Conversion type must be Post Click"
msgstr ""

#~ msgid "Conversion type must be Post Click or Post View"
#~ msgstr "El tipo de conversión debe ser Post Clic o Post Vista"

msgid "Conversion type required."
msgstr "Se requiere el tipo de conversión."

msgid "Conversion URL must end in trailing asterisk (*)"
msgstr "La URL de conversión debe terminar con un asterisco final (*)"

msgid "Conversion URL must not contain any whitespace"
msgstr "La URL de conversión no debe contener espacios en blanco"

msgid "Conversion URL must start with https://"
msgstr "La URL de conversión debe comenzar con https://"

msgid "Conversion URL Pattern"
msgstr "Patrón de URL de conversión"

msgid "Conversion URL required."
msgstr "Se requiere una URL de conversión."

msgid "Conversions"
msgstr "Conversiones"

msgid "Convert customers with AI-powered search ads"
msgstr "Convierta a los clientes con anuncios de búsqueda impulsados por IA"

msgid "Copy this and keep this safe!"
msgstr "¡Copie esto y guárdelo en un lugar seguro!"

msgid "Cost per Acquisition"
msgstr "Costo por adquisición"

msgid "Cost per click"
msgstr "Costo por clic"

msgid "Could not logout at this time. Please try again later."
msgstr "No se pudo cerrar la sesión en este momento. Por favor, inténtelo de nuevo más tarde."

msgid "Country"
msgstr "País"

msgid "Country is required"
msgstr "Se requiere el país"

msgid "Country Targeting"
msgstr "Segmentación por país"

msgid "CPA"
msgstr "CPA"

msgid "CPC (Clicks)"
msgstr "CPC (clics)"

msgid "CPM (Impressions)"
msgstr "CPM (impresiones)"

msgid "CPSV (Site Visits)"
msgstr "CPSV (visitas al sitio)"

msgid "Create & Submit For Approval"
msgstr "Crear y enviar para aprobación"

msgid "Create ad"
msgstr "Crear anuncio"

msgid "Create new ad"
msgstr "Crear nuevo anuncio"

msgid "Create new keypair?"
msgstr "¿Crear un nuevo par de claves?"

msgid "create now"
msgstr "crear ahora"

msgid "created"
msgstr "creado"

msgid "CTR"
msgstr "CTR"

msgid "Custom"
msgstr "Personalizado"

msgid "Daily"
msgstr "Diario"

msgid "Dashboard"
msgstr "Panel de control"

msgid "Data not valid for this private key"
msgstr "Datos no válidos para esta clave privada"

msgid "Decrypt Conversion Data?"
msgstr "¿Descifrar datos de conversión?"

msgid "Define how you want your campaign to run."
msgstr "Defina cómo quiere que se publique su campaña."

msgid "Define the number of days Brave will observe conversions and attribute them to the campaign."
msgstr "Defina el número de días que Brave observará las conversiones y las atribuirá a la campaña."

msgid "Disabled because you haven’t made any changes"
msgstr "Deshabilitado porque no ha realizado ningún cambio"

msgid "Disabled due to validation errors"
msgstr "Deshabilitado debido a errores de validación"

msgid "Discard ad"
msgstr "Descartar anuncio"

msgid "Discard campaign"
msgstr "Descartar campaña"

msgid "Dismissal Rate"
msgstr "Tasa de rechazo"

msgid "Dismissals"
msgstr "Rechazos"

msgid "Documentation can be found <0>here</0> on how to use the API."
msgstr "Puede encontrar documentación <0>aquí</0> sobre cómo usar la API."

msgid "Domain"
msgstr "Dominio"

msgid "Domain is required"
msgstr "Se requiere dominio"

msgid "Domain must not contain any whitespace"
msgstr "El dominio no debe contener espacios en blanco"

msgid "Domain to advertise"
msgstr "Dominio para publicitar"

msgid "Don’t see the email?"
msgstr "¿No ve el correo electrónico?"

#~ msgid "Don’t see the email? Check your spam folder or <0>try again.</0>"
#~ msgstr "Don’t see the email? Check your spam folder or <0>try again.</0>"

#~ msgid "Don't show this message again"
#~ msgstr ""

msgid "Download Report"
msgstr "Descargar informe"

msgid "Downvotes"
msgstr "Votos negativos"

msgid "Draft"
msgstr "Borrador"

msgid "Drafts"
msgstr "Borradores"

msgid "Edit"
msgstr "Editar"

msgid "Edit {campaignName}"
msgstr "Editar {campaignName}"

msgid "Edit ad"
msgstr "Editar anuncio"

msgid "Email"
msgstr "Correo electrónico"

msgid "Email address is required"
msgstr "Se requiere una dirección de correo electrónico"

msgid "End"
msgstr "Finalización"

msgid "End Date"
msgstr "Fecha de finalización"

msgid "End date is required"
msgstr "Se requiere fecha de finalización"

msgid "End date must be after start date"
msgstr "La fecha de finalización debe ser posterior a la fecha de inicio"

msgid "End Time"
msgstr "Hora de finalización"

msgid "Enter a URL that indicates a desired action you want to measure, like a subscription or purchase confirmation page. Brave will count unique visits to that page as conversions if a user has seen or clicked your ad."
msgstr "Introduzca una URL que indique una acción deseada que desea medir, como una página de confirmación de suscripción o compra. Brave contará las visitas únicas a esa página como conversiones si un usuario ha visto o hecho clic en su anuncio."

msgid "Enter Ads Manager"
msgstr "Ingresar al Administrador de Anuncios"

msgid "Enter your email address to get a secure login link. Use this link to access your Brave Ads account."
msgstr "Ingrese su dirección de correo electrónico para obtener un enlace de inicio de sesión seguro. Use este enlace para acceder a su cuenta de Brave Ads."

msgid "Escape the low CTR of display banners"
msgstr "Escape del CTR bajo de los banners de visualización"

msgid "Example - https://brave.com/brave-rewards/"
msgstr "Ejemplo - https://brave.com/brave-rewards/"

msgid "Export"
msgstr "Exportar"

msgid "Failed to Fetch"
msgstr "Error al recuperar"

msgid "Failed to load image"
msgstr "Error al cargar la imagen "

#~ msgid "Failed to update profile: {0}"
#~ msgstr "Error al actualizar el perfil: {0}"

msgid "Failed to update profile: {errorMessage}"
msgstr ""

msgid "Failed to upload image"
msgstr "Error al subir la imagen"

msgid "Feedback"
msgstr "Retroalimentación"

msgid "File upload complete for \"{fileName}\""
msgstr "Carga de archivo completa para “{fileName}”"

msgid "Filter ads by name..."
msgstr "Filtrar anuncios por nombre…"

msgid "Finalize & submit"
msgstr "Finalizar y enviar"

msgid "First party tracking acknowledgement is required"
msgstr "Se requiere el reconocimiento de seguimiento de origen"

msgid "For businesses of all sizes. Create your campaign in minutes, starting with as little as $500 per month."
msgstr "Para empresas de todos los tamaños. Cree su campaña en minutos, a partir de tan solo $500 al mes."

msgid "Format"
msgstr "Formato"

msgid "From"
msgstr "De"

msgid "From a Brave sales representative"
msgstr "De un representante de ventas de Brave"

msgid "From a friend/colleague"
msgstr "De un amigo/colega"

msgid "From an influential person I follow online"
msgstr "De una persona influyente a la que sigo en línea"

msgid "Full Landing Page URL"
msgstr "URL de la página de destino completa"

msgid "Full name"
msgstr "Nombre completo"

msgid "Full Name"
msgstr "Nombre completo"

msgid "Full name is required"
msgstr "Se requiere el nombre completo"

msgid "General Ad Inquiries"
msgstr "Consultas generales sobre anuncios"

msgid "Generate"
msgstr "Generar"

msgid "Generate a keypair for your account. Brave Ads will use your account’s public key to sign and encrypt conversion data. Only your organization will have access to the private key, which can be used to decrypt and view conversion data."
msgstr "Genere un par de claves para su cuenta. Brave Ads usará la clave pública de su cuenta para firmar y cifrar los datos de conversión. Solo su organización tendrá acceso a la clave privada, que se puede usar para descifrar y ver los datos de conversión."

msgid "Generate a new API key?"
msgstr "¿Generar una nueva clave API?"

msgid "Generate API Key"
msgstr "Generar una clave API"

msgid "Generating a new API key will result in the deactivation of your previous key, rendering it unusable for future requests. Make sure to update your code with the new key to avoid disruptions in your application’s functionality."
msgstr "La generación de una nueva clave API dará como resultado la desactivación de su clave anterior, dejándola inutilizable para futuras solicitudes. Asegúrese de actualizar el código con la nueva clave para evitar interrupciones en la funcionalidad de la aplicación."

msgid "Get a free testing period"
msgstr ""

msgid "Get in touch with our team"
msgstr "Póngase en contacto con nuestro equipo"

msgid "Get in touch."
msgstr "Póngase en contacto."

msgid "Get incremental traffic with paid ads on the world's fastest growing search engine."
msgstr "Obtenga tráfico incremental con anuncios pagados en el motor de búsqueda de más rápido crecimiento del mundo."

msgid "Get login link"
msgstr "Obtener enlace de inicio de sesión"

msgid "Get started"
msgstr "Comenzar"

msgid "Get to your first million users with powerful ad placements on the world’s fastest-growing alternative browser & search engine."
msgstr "Consiga su primer millón de usuarios con potentes ubicaciones de anuncios en el navegador y motor de búsqueda alternativo de más rápido crecimiento del mundo."

msgid "Have specific questions?"
msgstr "¿Tiene preguntas específicas?"

msgid "Help Center"
msgstr "Centro de ayuda"

msgid "Hi"
msgstr "Hola"

msgid "Hourly"
msgstr "Cada hora"

msgid "I have reviewed and agree to the <0>Advertiser Privacy Policy</0> and platform <1>Terms of Service</1> applicable to Brave Ads."
msgstr "He revisado y acepto la <0>Política de privacidad del anunciante<0> y los <1>Términos de servicio</1> de la plataforma aplicables a Brave Ads."

msgid "I understand that first-party analytics are necessary for independent reporting."
msgstr "Entiendo que los análisis de origen son necesarios para la elaboración de informes independientes."

msgid "I understand that I can only run ad campaigns in English, Spanish, and Portuguese."
msgstr "Entiendo que solo puedo realizar campañas publicitarias en inglés, español y portugués."

msgid "I understand that payment is required before ad campaigns can be launched."
msgstr "Entiendo que se requiere un pago antes de que se puedan lanzar campañas publicitarias."

msgid "I would like to receive marketing emails about new features and promotions from Brave Ads"
msgstr "Me gustaría recibir correos electrónicos de marketing sobre nuevas funciones y promociones de Brave Ads"

msgid "Id"
msgstr "Identificación"

msgid "Image"
msgstr "Imagen"

msgid "Image failed to load"
msgstr "La imagen no pudo cargar"

msgid "Image URL must be hosted on our private CDN"
msgstr "La URL de la imagen debe estar alojada en nuestra CDN privada"

msgid "Image URL must be valid"
msgstr "La URL de la imagen debe ser válida"

msgid "Images"
msgstr "Imágenes"

msgid "Impressions"
msgstr "Impresiones"

msgid "In a blog post/news article"
msgstr "En una publicación de blog/artículo de noticias"

msgid "In a newsletter I subscribe to"
msgstr "En un boletín al que me suscribo"

msgid "In a post by Brave on LinkedIn"
msgstr "En una publicación de Brave en LinkedIn"

msgid "In a video on YouTube"
msgstr "En un video en YouTube"

msgid "In your local time this is"
msgstr "En su hora local, esto es"

msgid "Include campaigns that ended over 6 months ago"
msgstr "Incluir campañas que finalizaron hace más de 6 meses"

msgid "Increase Budget"
msgstr ""

msgid "Increase budget for campaign: <0>{campaignName}</0>?"
msgstr ""

#~ msgid "Incremental"
#~ msgstr "Incremental"

msgid "Invalid format"
msgstr "Formato no válido"

msgid "invalid session"
msgstr "sesión no válida"

msgid "Invalid Session"
msgstr "Sesión no válida"

msgid "Invalid Token"
msgstr "Token no válido"

msgid "iOS"
msgstr "iOS"

msgid "is not"
msgstr "no es"

msgid "It is a modern <0>advertiser-first</0> ad platform made to help brands reach new users. Brave Ads are discrete, unobtrusive first-party ads built directly into the browser and search engine, with familiar formats like push notifications and news-feed ads. Brave’s innovative on-device targeting respects a user’s desire for privacy while still delivering personalized ads based on their interests, which drives high ROI for advertisers."
msgstr "Es una moderna plataforma publicitaria que <0> prioriza al anunciante</0> creada para ayudar a las marcas a llegar a nuevos usuarios. Brave Ads son anuncios propios discretos y no invasivos integrados directamente en el navegador y el motor de búsqueda, con formatos familiares como notificaciones push y anuncios de noticias. La innovadora segmentación en el dispositivo de Brave respeta el deseo de privacidad del usuario y, al mismo tiempo, ofrece anuncios personalizados basados en sus intereses, lo que genera un alto retorno de la inversión para los anunciantes."

msgid "It is scheduled to start {campaignStartFromNow}."
msgstr "Está programada para iniciarse {campaignStartFromNow}."

msgid "Key copied"
msgstr "Clave copiada"

msgid "Keypairs"
msgstr "Pares de claves"

msgid "Language acknowledgement is required"
msgstr "Se requiere confirmación de idioma"

msgid "Last 30 days"
msgstr "Últimos 30 días"

msgid "Last 7 days"
msgstr "Últimos 7 días"

msgid "Last month"
msgstr "El mes pasado"

msgid "Learn More"
msgstr "Más información"

msgid "Let Brave pick categories for me."
msgstr "Dejar que Brave elija las categorías por mí."

msgid "Lifetime Budget"
msgstr "Presupuesto vitalicio"

msgid "Lifetime budget is required"
msgstr "Se requiere presupuesto vitalicio"

msgid "Lifetime budget must be ${MIN_PER_CAMPAIGN} or more"
msgstr "El presupuesto vitalicio debe ser de ${MIN_PER_CAMPAIGN} o más"

msgid "Lifetime budget must be higher for date range provided. Minimum ${min}."
msgstr "El presupuesto vitalicio debe ser mayor para el intervalo de fechas proporcionado. Mínimo ${min}."

msgid "Like your campaign's performance? Increase the budget to expand its reach and connect with more potential customers. Specify below how much you would like to add to your current budget of <0>{previousBudget}</0>"
msgstr ""

msgid "Linux"
msgstr "Linux"

msgid "Locations"
msgstr "Ubicaciones"

msgid "Log in"
msgstr "Iniciar sesión"

msgid "Log into your Brave Ads account"
msgstr "Inicie sesión en su cuenta de Brave Ads"

#~ msgid "Logging in"
#~ msgstr "Logging in"

msgid "Logout"
msgstr "Cerrar sesión"

msgid "macOS"
msgstr "macOS"

msgid "Make payment & submit for approval"
msgstr "Realizar el pago y enviarlo para su aprobación"

msgid "Make sure to include your brand name in the title OR body of the ad. Otherwise, it will be rejected."
msgstr "Asegúrese de incluir el nombre de su marca en el título O en el cuerpo del anuncio. De lo contrario, será rechazado."

msgid "Max 30 Characters"
msgstr "Máximo 30 caracteres"

msgid "Max 60 Characters"
msgstr "Máximo 60 caracteres"

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved."
msgstr "Modificar un anuncio lo pondrá de inmediato en revisión. Esto significa que ya no se mostrará a los usuarios hasta que sea aprobado."

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved. This ad is also shared across {campaignLength} campaigns. Any modifications made will be effective for all campaigns using this creative."
msgstr "Modificar un anuncio lo pondrá de inmediato en revisión. Esto significa que ya no se mostrará a los usuarios hasta que sea aprobado. Este anuncio también se comparte en todas las campañas de {campaignLength}. Cualquier modificación realizada será efectiva para todas las campañas que utilizan este creativo."

msgid "Modifying the state of this ad will effect more than one campaign:"
msgstr "Modificar el estado de este anuncio afectará a más de una campaña:"

msgid "Name"
msgstr "Nombre"

msgid "Name your ad set to distinguish it from others."
msgstr "Asigne un nombre a su conjunto de anuncios para distinguirlo de los demás."

msgid "New"
msgstr "Nuevo"

msgid "New ad"
msgstr "Nuevo anuncio"

msgid "New API Key"
msgstr "Nueva clave API"

msgid "New Campaign"
msgstr "Nueva campaña"

msgid "New campaigns typically take up to 48 hours to review during a regular U.S. business week."
msgstr "Las nuevas campañas suelen tardar hasta 48 horas en revisarse durante una semana laboral regular en Estados Unidos."

msgid "New Keypair"
msgstr "Nuevo par de claves"

msgid "New tab takeover"
msgstr "Dominio de nueva pestaña"

msgid "New tab takeovers"
msgstr "Dominio de nuevas pestañas"

#~ msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services"
#~ msgstr "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services"

msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services."
msgstr "Los nuevos usuarios se cambian al Buscador de Brave todos los días. No se pierda la oportunidad de cumplir con su intención de comprar sus productos o servicios."

msgid "Newsfeed"
msgstr "Fuente de noticias"

msgid "Newsfeed Ad"
msgstr "Anuncio en la fuente de noticias"

msgid "Newsfeed ads"
msgstr "Anuncios en la fuente de noticias"

msgid "Next"
msgstr "Siguiente"

msgid "No"
msgstr "No"

msgid "No {billingType} pricing available for {label}, contact selfserve@brave.com for help"
msgstr "No hay precios de {billingType} disponibles para {label}, contacte a selfserve@brave.com para obtener ayuda"

#~ msgid "no additional charge will be applied."
#~ msgstr ""

msgid "No conversion URL setup"
msgstr "No hay configuración de URL de conversión"

msgid "No file result"
msgstr "No hay resultado del archivo"

msgid "No previous ads available"
msgstr "No hay anuncios anteriores disponibles"

msgid "no session created"
msgstr "no se ha creado ninguna sesión"

#~ msgid "Not automatically redirected? Click this link to go to the dashboard."
#~ msgstr "Not automatically redirected? Click this link to go to the dashboard."

msgid "Notes"
msgstr "Notas"

msgid "Notification"
msgstr "Notificación"

msgid "Notification ad"
msgstr "Anuncio de notificación"

msgid "Notification ads"
msgstr "Anuncios de notificación"

msgid "Observation Window"
msgstr "Ventana de observación"

msgid "Observation window must be 1, 7, or 30 days."
msgstr "La ventana de observación debe ser de 1, 7 o 30 días."

msgid "Observation window required."
msgstr "Se requiere ventana de observación."

msgid "On / Off"
msgstr "Encendido / Apagado"

msgid "On a podcast I listen to"
msgstr "En un podcast que escucho"

msgid "On/Off"
msgstr "Encendido/Apagado"

msgid "Once confirmed, your account’s keypair will be replaced with the new keypair."
msgstr "Una vez confirmado, el par de claves de su cuenta se reemplazará por el nuevo par de claves."

#~ msgid "Or email <0>searchads@brave.com</0>"
#~ msgstr "Or email <0>searchads@brave.com</0>"

msgid "or sign in using a password"
msgstr "o inicie sesión usando una contraseña"

msgid "or sign in using a secure link"
msgstr "o inicie sesión usando un enlace seguro"

msgid "OS"
msgstr "Sistema Operativo"

msgid "Other (please specify)"
msgstr "Otro (por favor, especifique)"

msgid "Other accounts"
msgstr "Otras cuentas"

msgid "Other source"
msgstr "Otra fuente"

msgid "Password"
msgstr "Contraseña"

msgid "Pause"
msgstr "Pausar"

msgid "Pay with BAT to get <0>50% off</0> your first self-managed campaign."
msgstr "Pague con BAT para obtener un <0>50% de descuento</0> en su primera campaña autogestionada."

msgid "Payment"
msgstr "Pago"

msgid "Payment acknowledgement is required"
msgstr "Se requiere confirmación de pago"

msgid "Payment is required before launching your campaign."
msgstr "Se requiere el pago antes de lanzar su campaña."

msgid "Performance Report"
msgstr "Informe de rendimiento"

msgid "Platforms"
msgstr "Plataformas"

msgid "Please ask your Account Manager for reports on campaigns of this format."
msgstr "Por favor, consulte con su Gerente de Cuenta para obtener informes sobre campañas en este formato."

msgid "Please confirm your account’s new private key:"
msgstr "Por favor, confirme la nueva clave privada de su cuenta:"

msgid "Please enter a username and password"
msgstr "Por favor, introduzca un nombre de usuario y contraseña"

msgid "Please enter a valid amount"
msgstr ""

msgid "Please enter a valid domain, for example: brave.com"
msgstr "Por favor, introduzca un dominio válido, por ejemplo: brave.com"

msgid "Please enter a valid email address"
msgstr "Por favor, introduzca una dirección de correo electrónico válida"

msgid "Please enter a valid URL, for example https://brave.com"
msgstr "Por favor, introduzca una URL válida, por ejemplo, https://brave.com"

msgid "Please enter a valid URL, for example https://brave.com/product/*"
msgstr "Por favor, introduzca una URL válida, por ejemplo, https://brave.com/product/*"

msgid "Please enter an email."
msgstr "Por favor, introduzca un correo electrónico."

msgid "Please let us know how you heard about Brave Ads"
msgstr "Por favor, indíquenos cómo se enteró de Brave Ads"

msgid "Please let us know why you're interested in Brave Ads"
msgstr "Por favor, indíquenos por qué está interesado(a) en Brave Ads"

msgid "Please refresh the page to try again, or email <0/> for assistance."
msgstr "Por favor, actualice la página para volver a intentarlo o envíe un correo electrónico a <0/> para obtener ayuda."

msgid "Please see our <0>Advertiser Privacy Policy</0> and <1>Terms of Service</1> applicable to Brave Ads."
msgstr "Consulte nuestra <0>Política de privacidad del anunciante</0> y <1>Términos de servicio</1> aplicables a Brave Ads."

msgid "Please specify how you heard about Brave Ads"
msgstr "Por favor, especifique cómo se enteró de Brave Ads"

msgid "Preparing file for upload..."
msgstr "Preparando el archivo para cargarlo…"

msgid "Price"
msgstr "Precio"

msgid "Price is a required field"
msgstr "El precio es un campo obligatorio"

msgid "Pricing"
msgstr "Precio"

msgid "Pricing Type"
msgstr "Tipo de precio"

msgid "Pricing type is a required field"
msgstr "El tipo de precio es un campo obligatorio"

msgid "Pricing type must be CPM, CPC, or CPSV"
msgstr "El tipo de precio debe ser CPM, CPC o CPSV"

msgid "Primary region of business"
msgstr "Región principal de la empresa"

msgid "Primary region of business is required"
msgstr "Se requiere la región principal de la empresa"

msgid "Private Key"
msgstr "Clave privada"

msgid "Proceed"
msgstr "Proceder"

msgid "Profile"
msgstr "Perfil"

msgid "Profile API Key"
msgstr "Clave API del perfil"

msgid "Profile Details"
msgstr "Detalles del perfil"

msgid "Query parameter"
msgstr "Parámetro de consulta"

msgid "Query string parameters"
msgstr "Parámetros de cadena de consulta"

msgid "Reach 70+ million highly engaged, otherwise unreachable users."
msgstr "Llegue a más de 70 millones de usuarios altamente comprometidos, a los que de otro modo no llegaría. "

msgid "Reach new audiences you're missing"
msgstr "Llegue a nuevas audiencias que le falten"

msgid "Ready to reach your first million users? Start your first campaign today."
msgstr "¿Listo(a) para llegar a su primer millón de usuarios? Comience su primera campaña hoy mismo."

msgid "Remove"
msgstr "Eliminar"

msgid "Remove Conversion tracking"
msgstr "Eliminar seguimiento de conversión"

#~ msgid "Request another link."
#~ msgstr "Request another link."

msgid "Return"
msgstr "Volver"

msgid "Return to dashboard"
msgstr "Volver al panel de control"

msgid "Review"
msgstr "Revisar"

msgid "Review ads"
msgstr "Revisar anuncios"

#~ msgid "Risk Free"
#~ msgstr "Risk Free"

#~ msgid "Risk free trial for up to 30 days"
#~ msgstr "Prueba sin riesgos de hasta 30 días"

msgid "Sample Queries"
msgstr "Consultas de ejemplo"

msgid "Save"
msgstr "Guardar"

msgid "Save ad"
msgstr "Guardar anuncio"

msgid "Saving..."
msgstr "Guardando…"

msgid "Search homepage"
msgstr "Buscar página de inicio"

msgid "Search keyword"
msgstr "Buscar palabra clave"

msgid "Search keyword ads"
msgstr "Buscar anuncios de palabras clave"

msgid "searchads@brave.com"
msgstr "searchads@brave.com"

msgid "Select Account"
msgstr "Seleccionar cuenta"

msgid "Select one campaign to clone"
msgstr "Seleccione una campaña para clonar"

msgid "Select one campaign to edit"
msgstr "Seleccione una campaña para editar"

msgid "Select the ads you would like to include in this ad set. Only checked ads are included."
msgstr "Seleccione los anuncios que desea incluir en este conjunto de anuncios. Solo se incluyen los anuncios marcados."

msgid "Select the audience segments to target"
msgstr "Seleccione los segmentos de audiencia a los que desea dirigirse"

msgid "Select the geographic regions where your ads will be shown."
msgstr "Seleccione las regiones geográficas en las que se mostrarán sus anuncios."

msgid "Select the interest segments and platforms you would like to target."
msgstr "Seleccione los segmentos de interés y las plataformas a las que desea dirigirse."

msgid "Select the platforms to target"
msgstr "Seleccione las plataformas a las que se dirigirá"

msgid "Selected ads"
msgstr "Anuncios seleccionados"

msgid "Set up a trial campaign in minutes."
msgstr "Configure una campaña de prueba en minutos."

msgid "Setup Progress"
msgstr "Progreso de configuración"

msgid "Simple ads to reach digital trend-setters around the world"
msgstr "Anuncios simples para llegar a los líderes de tendencias digitales en todo el mundo"

msgid "Site Visit Rate"
msgstr "Tasa de visitas al sitio"

msgid "Site visits"
msgstr "Visitas al sitio"

msgid "Site Visits"
msgstr "Visitas al sitio"

msgid "Skip survey and continue"
msgstr "Saltar encuesta y continuar"

msgid "Something went wrong."
msgstr "Algo salió mal. "

msgid "Spend"
msgstr "Gastar"

msgid "Sponsored"
msgstr "Patrocinado"

msgid "Start"
msgstr "Empezar"

msgid "Start a campaign with BAT"
msgstr "Inicie una campaña con BAT"

msgid "Start Date"
msgstr "Fecha de inicio"

msgid "Start Date is required"
msgstr "Se requiere la fecha de inicio"

msgid "Start Date must be minimum of 2 days from today"
msgstr "La fecha de inicio debe ser de un mínimo de 2 días a partir de hoy"

msgid "Start fast and experiment"
msgstr "Empiece rápido y experimente"

msgid "Start Time"
msgstr "Hora de inicio"

msgid "Start your company profile"
msgstr "Inicie el perfil de su empresa"

msgid "State"
msgstr "Estado"

msgid "Status"
msgstr "Estado"

msgid "Street address"
msgstr "Dirección de la calle"

msgid "Street address is required"
msgstr "Se requiere la dirección de la calle"

msgid "Submit"
msgstr "Enviar"

msgid "Success! Check your email."
msgstr "¡Éxito! Revise su correo electrónico."

#~ msgid "Successfully logged in!"
#~ msgstr "Successfully logged in!"

msgid "Support"
msgstr "Soporte"

msgid "Take our survey!"
msgstr "¡Responda nuestra encuesta!"

#~ msgid "Tap into more than <0>9 billion</0> annual Web searches"
#~ msgstr "Tap into more than <0>9 billion</0> annual Web searches"

msgid "Target URL"
msgstr "URL de destino"

msgid "Target URL is a required field"
msgstr "La URL de destino es un campo obligatorio"

msgid "Target URL is not valid"
msgstr "La URL de destino no es válida"

msgid "Target URL not validated"
msgstr "URL de destino no validada"

msgid "Targeting"
msgstr "Segmentación"

msgid "Tell us why you're interested in Brave Ads"
msgstr "Cuéntenos por qué le interesa Brave Ads"

msgid "Terms & Conditions acknowledgement is required"
msgstr "Se requiere la aceptación de los Términos y Condiciones"

msgid "The <0>Basic Attention Token (BAT)</0> is Brave’s native crypto token. Brave Browser users who opt into <1>Brave Rewards</1> can earn BAT just for opting into viewing ads from the Brave Ads network. Earners can hold BAT like any other crypto asset, trade it, cash it in, or even donate it to their favorite creators."
msgstr "El <0>Basic Attention Token (BAT)</0> es el token criptográfico nativo de Brave. Los usuarios del navegador Brave que opten por <1>Brave Rewards</1> pueden ganar BAT sólo por ver anuncios de la red Brave Ads. Los ganadores pueden conservar el BAT como cualquier otro criptoactivo, comerciar con él, canjearlo o incluso donarlo a sus creadores favoritos."

msgid "The campaign will likely stop running before the end date provided due to budget constraints."
msgstr "Es probable que la campaña deje de publicarse antes de la fecha de finalización proporcionada debido a restricciones presupuestarias."

#~ msgid "The current balance for your account that you can attribute to a new Campaign."
#~ msgstr ""

msgid "The current value for this metric may be an underestimate."
msgstr "El valor actual de esta métrica puede ser una subestimación."

msgid "The following query string parameters will be added to your landing page URLs. This will allow you to track the performance of your ads."
msgstr "Los siguientes parámetros de cadena de consulta se agregarán a las URL de su página de destino. Esto le permitirá realizar un seguimiento del rendimiento de sus anuncios."

msgid "The magic link you have requested has either expired or has already been used. Please return to the login page and try again."
msgstr "El enlace mágico que ha solicitado ha caducado o ya se ha utilizado. Vuelva a la página de inicio de sesión e inténtelo de nuevo."

msgid "The username or password did not match our records."
msgstr "El nombre de usuario o la contraseña no coincidieron con nuestros registros."

msgid "This is a news display ad, it wll look like part of the news feed."
msgstr "Este es un anuncio de noticias, se verá como parte de la fuente de noticias."

msgid "This key is unique to you, make sure to safely store it and avoid sharing it with others to prevent unauthorized access."
msgstr "Esta clave es única para usted, asegúrese de guardarla de forma segura y evite compartirla con otros para evitar el acceso no autorizado."

msgid "This month"
msgstr "Este mes"

msgid "Time Zone"
msgstr "Zona horaria"

msgid "Title"
msgstr "Título"

msgid "Title Preview"
msgstr "Vista previa del título"

msgid "To"
msgstr "Para"

msgid "To protect user’s privacy, verified ad conversion data is encrypted so that the identities of converted users remain anonymous to Brave. You can decrypt the conversion data in the CSV file by providing your private key here. If no key is provided, you will receive the encrypted conversion data. Your private key will never be sent to or stored on any Brave servers."
msgstr "Para proteger la privacidad del usuario, los datos de conversión de anuncios verificados se cifran para que las identidades de los usuarios convertidos permanezcan anónimas para Brave. Puede descifrar los datos de conversión en el archivo CSV proporcionando su clave privada aquí. Si no se proporciona ninguna clave, recibirá los datos de conversión cifrados. Su clave privada nunca se enviará ni se almacenará en ningún servidor de Brave."

msgid "Today"
msgstr "Hoy"

#~ msgid "Try a free one-month test to see how Brave Search Ads perform for your brand."
#~ msgstr "Try a free one-month test to see how Brave Search Ads perform for your brand."

#~ msgid "Try a free one-month test to see how Brave Search Ads perform for your business."
#~ msgstr "Haga una prueba gratuita de un mes para ver el rendimiento de los anuncios del Buscador de Brave en su empresa."

msgid "Try Brave Search Ads risk-free to see how the ads perform for your website."
msgstr ""

msgid "Unable to clone campaign"
msgstr "No se puede clonar la campaña"

msgid "Unable to create a new campaign"
msgstr "No se puede crear una nueva campaña"

msgid "Unable to Create Campaign."
msgstr "No se puede crear una campaña."

msgid "Unable to create payment session. Please try again."
msgstr "No se puede crear una sesión de pago. Por favor, inténtelo de nuevo."

msgid "Unable to decrypt conversion data"
msgstr "No se pueden descifrar los datos de conversión"

msgid "Unable to download CSV"
msgstr "No se puede descargar CSV"

msgid "Unable to get campaign information for ad"
msgstr "No se puede obtener la información de la campaña para el anuncio"

msgid "Unable to get profile information"
msgstr "No se puede obtener información del perfil"

msgid "Unable to load ad"
msgstr "No se puede cargar el anuncio"

#~ msgid "Unable to load reporting details for campaign: {0}"
#~ msgstr "No se pueden cargar los detalles de los informes de la campaña: {0}"

msgid "Unable to load reporting details for campaign: {campaignName}"
msgstr ""

#~ msgid "Unable to login, link has expired or has already been used."
#~ msgstr "Unable to login, link has expired or has already been used."

msgid "Unable to login."
msgstr "No se puede iniciar sesión."

msgid "Unable to register your organization at this time. Please try again later."
msgstr "No se puede registrar su organización en este momento. Por favor, inténtelo de nuevo más tarde."

msgid "Unable to retrieve Campaign data."
msgstr "No se pueden recuperar los datos de la campaña."

msgid "Unable to retrieve images"
msgstr "No se pueden recuperar imágenes"

msgid "Unable to save ad"
msgstr "No se puede guardar el anuncio"

msgid "Unable to submit registration"
msgstr "No se puede enviar el registro"

msgid "Unable to update Advertiser."
msgstr "No se puede actualizar el anunciante."

msgid "Unable to Update Campaign."
msgstr "No se puede actualizar la campaña."

msgid "Unable to upload image"
msgstr "No se puede subir la imagen"

msgid "Unexpected error validating your credentials. Please try again later."
msgstr "Error inesperado al validar sus credenciales. Por favor, inténtelo de nuevo más tarde."

msgid "Unknown"
msgstr "Desconocido"

msgid "Update & Submit For Approval"
msgstr "Actualizar y enviar para aprobación"

msgid "Upload"
msgstr "Subir"

msgid "Upload Image"
msgstr "Subir imagen"

msgid "Upload new image"
msgstr "Subir nueva imagen"

msgid "Uploaded images can be shared across different ad sets within a Campaign. For best quality, upload images at 900x750px resolution. Images will be automatically scaled to this size."
msgstr "Las imágenes subidas se pueden compartir entre diferentes conjuntos de anuncios dentro de una campaña. Para obtener la mejor calidad, cargue imágenes con una resolución de 900x750px. Las imágenes se escalarán automáticamente a este tamaño."

msgid "Uploading file..."
msgstr "Cargando archivo…"

msgid "Upvotes"
msgstr "Votos a favor"

msgid "URL is valid."
msgstr "La URL es válida."

msgid "URL must not contain any whitespace"
msgstr "La URL no debe contener espacios en blanco"

msgid "URL must start with https://"
msgstr "La URL debe comenzar con https://"

msgid "URL Pattern"
msgstr "Patrón de URL"

msgid "URL should have a trailing asterisk - Example: https://brave.com/products/*"
msgstr "La URL debe tener un asterisco al final - Ejemplo: https://brave.com/products/*"

msgid "Use existing ads"
msgstr "Usar anuncios existentes"

msgid "Validating URL..."
msgstr "Validando URL…"

msgid "Value"
msgstr "Valor"

msgid "Verified Conversions Report"
msgstr "Informe de conversiones verificadas"

msgid "View profile"
msgstr "Ver perfil"

msgid "View-through Conversions"
msgstr "Conversiones por visualización"

msgid "Visit Help Center"
msgstr "Visitar el centro de ayuda"

msgid "We value your feedback and would love to hear your thoughts on your recent experience. <0/>Your input will help us improve the Brave Ads platform to better meet your needs. <1/>It only takes a few minutes and your participation will be greatly appreciated! <2/>Thank you for using Brave Ads! <3/>"
msgstr "Valoramos sus comentarios y nos encantaría conocer su opinión sobre su experiencia reciente. <0/>Sus comentarios nos ayudarán a mejorar la plataforma de Brave Ads para satisfacer mejor sus necesidades. <1/>¡Solo toma unos minutos y su participación será muy apreciada! <2/>¡Gracias por usar Brave Ads! <3/>"

msgid "Website must not contain any whitespace"
msgstr "El sitio web no debe contener ningún espacio en blanco"

msgid "Website must start with https://"
msgstr "El sitio web debe comenzar con https://"

msgid "What is Brave Ads?"
msgstr "¿Qué es Anuncios de Brave?"

msgid "What’s BAT?"
msgstr "¿Qué es BAT?"

msgid "What’s Brave?"
msgstr "¿Qué es Brave?"

msgid "Where did you hear about Brave Ads"
msgstr "¿Dónde se enteró de Brave Ads?"

msgid "While looking up alternatives to Google Ads"
msgstr "Al buscar alternativas a Google Ads"

msgid "Why use Brave Ads?"
msgstr "¿Por qué utilizar Anuncios de Brave?"

msgid "Windows"
msgstr "Windows"

msgid "Work email"
msgstr "Correo electrónico de trabajo"

msgid "Yes"
msgstr "Sí"

msgid "You are about to close this campaign."
msgstr ""

msgid "You are attempting to create a new keypair, this will replace any of your account’s existing keypairs. Please note, previous keypairs cannot be retrieved or used once replaced."
msgstr "Está intentando crear un nuevo par de claves, que reemplazará cualquiera de los pares de claves existentes de su cuenta. Tenga en cuenta que los pares de claves anteriores no se pueden recuperar ni utilizar una vez reemplazados."

msgid "You are logging into the Brave Ads Manager Dashboard."
msgstr "Usted está iniciando sesión en el panel de control del Administrador de Anuncios de Brave."

#~ msgid "You have {0} errors that must be fixed before submitting."
#~ msgstr "Tiene {0} errores que deben corregirse antes de enviar."

msgid "You have {errorCount} errors that must be fixed before submitting."
msgstr ""

msgid "You have unsaved changes"
msgstr "Tiene cambios sin guardar"

msgid "You may have access to multiple accounts. Switch between them here."
msgstr "Es posible que tenga acceso a varias cuentas. Cambie entre ellas aquí."

msgid "You will soon receive an email with the next steps of registration. While you wait, we recommend you check out our help center to get helpful information for getting started with Brave Ads."
msgstr "Pronto recibirá un correo electrónico con los próximos pasos del registro. Mientras espera, le recomendamos que consulte nuestro centro de ayuda para obtener información útil para comenzar con Brave Ads."

#~ msgid "Your account has a balance of {0} USD. This will be applied to your campaign"
#~ msgstr ""

msgid "Your account has a balance of {accountBalance} USD. This will be applied to your campaign"
msgstr ""

#~ msgid "Your account has a balance of ${0} USD. This will be applied to your campaign"
#~ msgstr ""

msgid "Your account’s new private key will be:"
msgstr "La nueva clave privada de su cuenta será:"

#~ msgid "Your account’s new public key will be:"
#~ msgstr "Your account’s new public key will be:"

msgid "Your account’s public key:"
msgstr "La clave pública de su cuenta:"

msgid "Your campaign has been created and is now being reviewed by our ads team. <0/>We’ll be in contact as soon as your campaign is approved and activated."
msgstr "Su campaña ha sido creada y ahora está siendo revisada por nuestro equipo de anuncios. <0/>Nos pondremos en contacto con usted tan pronto como se apruebe y active su campaña."

msgid "Your campaign has been updated! <0/>If you added new ads, we’ll be in contact as soon as they are approved and activated. <1/>Thank you for using Brave Ads!"
msgstr "¡Su campaña ha sido actualizada! <0/>Si agregó nuevos anuncios, nos pondremos en contacto con usted tan pronto como se aprueben y activen. <1/>¡Gracias por usar Brave Ads!"

msgid "Your current account balance"
msgstr ""

msgid "Your trial campaign will be reviewed by an Account Manager. Add any notes or questions for them here."
msgstr "Su campaña de prueba será revisada por un Gerente de Cuenta. Agregue cualquier nota o pregunta para ellos aquí."

msgid "Zip / Postal Code"
msgstr "Código postal"
